import moment from "moment"

const situationProOptions = [
    { label: 'Étudiant(e)', value: 'etudiant' },
    { label: 'Apprenti(e)', value: 'apprenti' },
    { label: 'Retraité(e)', value: 'retraite' },
    { label: 'Demandeur d’emploi', value: 'demandeur_emploi' },
    { label: 'Enceinte', value: 'enceinte' },
    { label: 'En situation de handicap', value: 'en_situation_de_handicap' },
    { label: 'Inapte au travail', value: 'inapte_au_travail' },
    { label: 'Actif (salarié / indépendant)', value: 'actif' },
]


export const allFields = {
    nom: {
        fieldType: 'text', label: 'Nom', name: 'nom', ph: 'Votre nom', errmsgname: 'nom', size: 'fsSemi'
    },
    prenom: {
        fieldType: 'text', label: 'Prénom', name: 'prenom', ph: 'Votre prénom', errmsgname: 'prenom', size: 'fsSemi'
    },
    adresse: {
        fieldType: 'text', label: 'Adresse', name: 'address', ph: 'Votre adresse', errmsgname: 'adresse', size: 'fsSemi'
    },
    date_de_naissance: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_naissance', ph: 'Votre date de naissance', errmsgname: 'date', size: 'fsSemi'
    },
    mail: {
        fieldType: 'email', label: 'Email', name: 'email', ph: 'Exemple: monmail@gmail.com', errmsgname: 'mail'
    },
    agentId: {
        fieldType: 'text', label: 'Identifiant de l\'agent', name: 'agentId',
        ph: 'Identifiant de l\'agent', size: 'fsSemi'
    },
    codepostal: {
        fieldType: 'text',
        label: 'Code postal', name: 'codepostal',
        ph: 'Exemple: 75000', size: 'fsSemi'
    },
    telephone: {
        fieldType: 'text',
        label: 'Télephone portable', name: 'telephone',
        ph: 'Exemple: 0612345678', size: 'fsSemi'
    },
    password: {
        fieldType: 'password',
        label: 'Mot de passe', name: 'password',
        ph: 'Mon Mot De Passe', errmsgname: 'mot de passe'
    },
    password_confirmation: {
        fieldType: 'password',
        label: 'Confirmation', name: 'password_confirmation',
        ph: 'Mon Mot De Passe', errmsgname: 'confirmation'
    },
    nom_conjoint: {
        fieldType: 'text', label: 'Nom du conjoint', name: 'nom_conjoint', ph: 'Nom de votre conjoint', errmsgname: 'nom_conjoint', size: 'fsSemi', conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    prenom_conjoint: {
        fieldType: 'text',
        label: 'Prénom du conjoint', name: 'prenom_conjoint', ph: 'Prénom de votre conjoint', errmsgname: 'prenom_conjoint', size: 'fsSemi', conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    nom_enfant: {
        fieldType: 'text', label: 'Nom enfant', name: 'nom_enfant', ph: 'Nom de votre enfant', errmsgname: 'nom_enfant', size: 'fsSemi'
    },
    prenom_enfant: {
        fieldType: 'text', label: 'Prénom enfant', name: 'prenom_enfant', ph: 'Prénom de votre enfant', errmsgname: 'prenom_enfant', size: 'fsSemi'
    },
    date_de_naissance_conjoint: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_naissance_conjoint', ph: 'Date de naissance conjoint', errmsgname: 'date de conjoint', size: 'fsSemi'
    },
    date_de_naissance_enfant: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_de_naissance_enfant', ph: 'Date de naissance enfant', errmsgname: 'date de enfant', size: 'fsSemi'
    },
    salaire_annuelle: {
        fieldType: 'number',
        label: "Salaire (Annuel)", name: 'salaire_annuelle',
        ph: '0€', errmsgname: 'salaire annuelle', size: 'fsSemi'
    },
    charge_deductible: {
        fieldType: 'number',
        label: "Charge(Déductible)", name: 'charge_deductible',
        ph: '0€', errmsgname: 'charge', size: 'fsSemi'
    },
    il_ya_trois_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(3, 'month').format('MMMM'), name: 'trimestre_1',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },
    il_ya_deux_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(2, 'month').format('MMMM'), name: 'trimestre_2',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },
    il_ya_un_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(1, 'month').format('MMMM'), name: 'trimestre_3',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },

    nom_prenom: {
        fieldType: 'text',
        label: 'Votre Nom et prenom', name: 'username',
        ph: 'Nom et prenom', errmsgname: 'nom', size: 'fsSemi'
    },
    telephone: {
        fieldType: 'text',
        label: 'Votre Télephone portable', name: 'telephone',
        ph: 'Téléphone portable', errmsgname: 'numero de telephone', size: 'fsSemi'
    },
    message: {
        comp: 'textarea', label: 'Votre message', name: 'message',
        ph: 'Votre Message',
    },
    situation_familiale: {
        fieldType: 'radio', label: 'Votre situation familiale', name: 'situation_familiale',
        ph: 'Votre situation familiale', choices: [
            { value: "célibataire", label: "Célibataire" },
            { value: "marié_avec_enfant", label: "Marié avec enfant" },
            { value: "marié_sans_enfant", label: "Marié sans enfant" },
        ]
    },
    isAvailable: {
        fieldType: 'radio', label: 'En congé', name: 'isAvailable',
        ph: 'En congé', choices: [
            { value: "true", label: "Non" },
            { value: "false", label: "Oui" },
        ]
    },
    situation_professionnelle: {
        fieldType: 'checkbox2', label: 'Votre situation Professionnelle', name: 'situation_professionnelle',
        ph: 'Votre situation Professionnelle', choices: situationProOptions
    },
    situation_professionnelle_conjoint: {
        fieldType: 'checkbox2', label: 'Situation Professionnelle de votre conjoint', name: 'situation_professionnelle_conjoint',
        ph: 'Situation Professionnelle de votre conjoint', choices: situationProOptions,
        conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    noteTitle: {
        fieldType: 'text', label: 'Titre de la note', name: 'title',
        ph: 'Titre de la note', errmsgname: "message"
    },
    docTitle: {
        fieldType: 'text', label: 'Titre du document', name: 'title',
        ph: 'Titre du document', errmsgname: "message"
    },
    docFiles: {
        fieldType: 'files', label: 'Fichier du document', name: 'docFiles',
        ph: '', errmsgname: "message"
    },
    cancelReason: {
        fieldType: 'textarea', label: 'Les raisons de ma résiliation', name: 'cancelReason',
        ph: 'Les raisons de ma résiliation', errmsgname: "message"
    },

    nom_prenom: {
        fieldType: 'text', label: 'Nom complet', name: 'userName',
        ph: 'Nom et prenom', size: 'fsSemi'
    },
    mail_subject: {
        fieldType: 'text', label: 'Sujet du mail', name: 'sujet',
        ph: 'Sujet du mail', size: 'fsSemi'
    },
    mail_files: {
        fieldType: 'files', label: 'Fichier du document', name: 'files',
        ph: '', errmsgname: "message"
    },
    message: {
        fieldType: 'textarea', label: 'Votre message', name: 'message',
        ph: 'Votre Message',
    },
    note: {
        fieldType: 'textarea', label: 'Votre note', name: 'note',
        ph: 'Votre note',
    },

    numero_secu: {
        fieldType: 'text', label: 'Identifiants', name: 'numero_secu',
        ph: '0123456.....', size: 'fsSemi'
    },

    structure: {
        fieldType: 'text', label: 'Organisme', name: 'structure',
        ph: 'Pole Emplois,CAF,etc...', size: 'fsSemi'
    },

    title: {
        fieldType: 'text', label: 'Titre', name: 'title',
        ph: 'Le titre', size: 'fsSemi'
    },
    amount: {
        fieldType: 'text', label: 'Montant', name: 'montant',
        ph: 'Ex: 5$', size: 'fsSemi'
    },


    keyWord: {
        fieldType: 'text', label: 'Rechercher une simulation avec le nom,prenom,email du client', name: 'keyWord',
        ph: 'Nom,prenom,email du client', size: 'fsSemi'
    },

    from: {
        fieldType: 'date', label: 'De', name: 'from', size: 'fsSemi'
    },
    to: {
        fieldType: 'date', label: 'A', name: 'to', size: 'fsSemi'
    },
    dataLimit: {
        fieldType: 'number', label: 'Nombre de Ligne(Par defaut 100)',
        ph: 'Nombre de Ligne(Par defaut 100)', name: 'dataLimit', size: 'fsSemi'
    },

    lien_calendly: {
        fieldType: 'text', label: 'Lien calendly', name: 'lien',
        ph: 'Lien calendly', size: 'fsSemi'
    },

    starsCount: {
        fieldType: 'number', label: 'Nombre d\'etoiles', name: 'stars',
        ph: '1,2,etc..', size: 'fsSemi'
    },
    reviewDate: {
        fieldType: 'date', label: 'Date de l\'avis', name: 'messageDate',
        size: 'fsSemi'
    },

    reviewDate: {
        fieldType: 'date', label: 'Date de l\'avis', name: 'messageDate',
        size: 'fsSemi'
    },
    articleDesc: {
        fieldType: 'textarea', label: 'Description de l\'article', name: 'description',
        size: 'fsSemi'
    },
    articleBanner: {
        fieldType: 'file', label: 'Banniere de l\'article', name: 'banner',
        ph: '', errmsgname: "message"
    },

}


const selectFieldsWithExternalOptions = (options) => {
    return {
        phase: {
            fieldType: 'select', label: 'Phase', name: 'phase', size: 'fsSemi', options,
            optionsLabelKey: 'ph', optionsValueKey: 'ph'
        },
        aide: {
            fieldType: 'select', label: 'Aide', name: 'aide', size: 'fsSemi', options,
            optionsLabelKey: 'title', optionsValueKey: 'title'
        },
        grade: {
            fieldType: 'select', label: 'Role de l\'agent', name: 'grade',
            options, optionsLabelKey: 'label', optionsValueKey: 'value'
        },
        simulationStatus: {
            fieldType: 'select', label: 'Statut', name: 'simulationStatus',
            options, optionsLabelKey: 'label', optionsValueKey: 'label'
        },
        category: {
            fieldType: 'select', label: 'Categorie', name: 'categoryId',
            options, optionsLabelKey: 'name', optionsValueKey: '_id'
        },
        isAvailable: {
            fieldType: 'select', label: 'En congé', name: 'isAvailable',
            options: [
                { value: "false", label: "Oui" },
                { value: "true", label: "Non" },
            ], optionsLabelKey: 'label', optionsValueKey: 'value'
        },

    }
}

export const loginFields = [
    allFields.mail, allFields.password
]

export const createClientFields = [
    allFields.nom, allFields.prenom, allFields.mail,
    allFields.codepostal, allFields.telephone, allFields.adresse
]
export const updateGenericAccountProfilFields = [
    allFields.nom, allFields.prenom, allFields.mail,
    allFields.codepostal, allFields.telephone, allFields.adresse, allFields.password, allFields.password_confirmation
]

export const confirmUpdateWithPasswordsFields = [
    allFields.password, allFields.password_confirmation
]

export const newMailFields = [
    allFields.mail_subject, allFields.message, allFields.mail_files
]

export const newNoteFields = [
    allFields.noteTitle, allFields.note
]

export const newClientGouvStructurePassFields = [
    allFields.structure, allFields.numero_secu, allFields.password
]

export const newTransactionFields = (phasesOpts, aidesOpt) => {
    return [
        allFields.title,
        selectFieldsWithExternalOptions(phasesOpts).phase,
        selectFieldsWithExternalOptions(aidesOpt).aide,
        allFields.amount
    ]
}

export function filterSimFields(statusList) {
    return [
        allFields.keyWord, allFields.from, allFields.to,
        selectFieldsWithExternalOptions(statusList).simulationStatus,
        allFields.dataLimit
    ];
}


export const newAdminFields = (gradeOpts) => {
    return [
        ...createClientFields,
        selectFieldsWithExternalOptions(gradeOpts).grade,
        allFields.lien_calendly,
        ...confirmUpdateWithPasswordsFields
    ]
}

export const upAdminFields = () => {
    return [
        ...createClientFields,
        allFields.lien_calendly,
        selectFieldsWithExternalOptions(null).isAvailable,
        ...confirmUpdateWithPasswordsFields
    ]
}

export const newTrustPilotReview = [
    allFields.nom_prenom, allFields.starsCount, allFields.message, allFields.reviewDate
]

export const newBlogArticleFields = (categoryOpts) => {
    return [
        allFields.title,
        selectFieldsWithExternalOptions(categoryOpts).category,
        allFields.articleDesc, allFields.articleBanner,
    ]
}