import { Field } from 'formik'
import React from 'react'
import { useState } from 'react';



export function InputPasswordWithFormik(props) {
    const { name, ph, label, type, fieldStyle, ...rest } = props;
    const [pwdBtn, setPwdBtn] = useState("Voir");
    const [fieldType, setFT] = useState("password");

    function handlePwdView(event) {
        if (event.target.previousSibling.getAttribute('type') == 'password') {
            event.target.previousSibling.setAttribute('type', 'text')
            setPwdBtn("Caché")
        } else {
            event.target.previousSibling.setAttribute('type', 'password')
            setPwdBtn("Voir")
        }
    }
    return (
        <div className={"formSegment  "}>
            <label> {label} </label>
            <section>
                <Field type={fieldType} name={name}
                    placeholder={ph}
                    autoComplete={'new-password'}
                />
                <span className="password-icon"
                    onClick={(event) => handlePwdView(event)}>
                    {pwdBtn}
                </span>
            </section>
        </div>
    )
}