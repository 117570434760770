import { PhXCircle } from "./Icons";
import { useModal } from "../contexts/modal";

export const Modal = ({ props }) => {
    const { modalVisible, modalContent, hideModal } = useModal();
    const className = props && props.className ? props.className : ""
    if (!modalVisible) {
        return null;
    }
    return <div className="modal">
        <section className="modal-body">
            <div className="mb-head-closer">
                <PhXCircle onClick={() => hideModal()}
                    style={{ fontSize: '30px' }} />
            </div>
            {modalContent}
        </section>
    </div>
}

export const ConfirmationMessage = ({ props }) => {
    const { hideModal } = useModal();
    const { text, functionAfterConfirm, param } = props
    return <div className="confirmationModal">
        <p>
            {text}
        </p>

        <section>
            <span onClick={() => functionAfterConfirm(param)}>Confirmer</span>
            <span onClick={() => hideModal()}>Annuler</span>
        </section>
    </div>
}