import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { PhCalendarBold, PhChartLineUpBold, PhUserFill } from '../GlobalComponents/Icons'
import moment from 'moment/moment'
import { useDispatch } from 'react-redux'
import { setCurrentClientToStore } from '../Store/actions/clientActions'
import { PreviewClient } from './client/list'
import { adminAndAgent } from '../Rawdata/links'
import { statusList } from './simulations/update'
import { useModal } from '../contexts/modal'

function mapClientStatusTypeToRequest() {
    let newArray = []
    for (let j = 0; j < statusList.length; j++) {
        newArray.push(axios.get('/admin/simulation/client/status/' + statusList[j].label))
    }
    return newArray;
}

export function DashboardStats({ authed }) {
    const { _id, grade } = authed;
    const [simulationCount, setSC] = useState('');
    const [thirtySimulationCount, setTSC] = useState('');
    const [clientsCount, setCC] = useState('');
    const [thirtyDayClientCount, setTDCC] = useState('');
    const [articlesCounts, setAC] = useState('');
    const [thirtyDayArticlesCounts, setTDAC] = useState('');
    const [articlesCommentCounts, setACC] = useState('');
    const [thirtyDayArticlesCommentCounts, setTDACC] = useState('');
    const [articlesViewsCounts, setAVC] = useState('');


    // Statut Prospect
    const [clientStatusCount, setClientStatusCount] = useState([])
    const availableRoles = ['Admin', 'Agent', 'Commercial', 'Bloger'];
    const availableRolesExceptBloger = ['Admin', 'Agent', 'Commercial'];



    useEffect(() => {
        if (availableRolesExceptBloger.includes(grade)) {
            let clientReqUrl = ''
            if (grade == 'Agent') {
                clientReqUrl = 'agent/' + _id + '/clientsCount'
            } else {
                clientReqUrl = 'clientsCount'
            }

            const promise1 = axios.get('/admin/' + clientReqUrl);
            const promise2 = axios.get('/admin/' + clientReqUrl + '-last30Day');
            const promise3 = axios.get('/admin/simulationsCount');
            const promise4 = axios.get('/admin/simulationsCount-last30Day');
            const requestUrlsArray = [promise1, promise2, promise3, promise4]

            Promise.all(requestUrlsArray).then((res) => {
                setCC(res[0].data);
                setTDCC(res[1].data);
                setSC(res[2].data);
                setTSC(res[3].data);
                // setAVC(res[4].data);
            })
            Promise.all(mapClientStatusTypeToRequest()).then((res) => {
                for (let u = 0; u < statusList.length; u++) {
                    setClientStatusCount(prev => [...prev, { type: statusList[u].label, count: res[u].data }])
                }
            })
        } else if (grade === 'Bloger') {
            const promise1 = axios.get('/admin/blog/articlesCount');
            const promise2 = axios.get('/admin/blog/articlesCount-last30Day');
            const promise3 = axios.get('/admin/blog/commentsCount');
            const promise4 = axios.get('/admin/blog/commentsCount-last30Day');
            const promise5 = axios.get('/admin/blog/articlesViewsCount');
            const requestUrlsArray = [promise1, promise2, promise3, promise4, promise5]

            Promise.all(requestUrlsArray).then((res) => {
                setAC(res[0].data);
                setTDAC(res[1].data);
                setACC(res[2].data);
                setTDACC(res[3].data);
                setAVC(res[4].data);
            })
        }

    }, [])

    return (
        <div className='dashboard-stats'>
            <section className="ds-left">
                <h4 className='withIcon'> <PhChartLineUpBold /> Statistiques globales</h4>
                <div className='ds-statsList'>
                    {
                        grade == 'Bloger' ? <>

                            <DashStatsBox props={{ count: articlesCounts, type: <>Articles  Publies</> }} />
                            <DashStatsBox props={{ count: articlesViewsCounts, type: <>Vues sur  article </> }} />
                            <DashStatsBox props={{ count: articlesCommentCounts, type: <>Commentaires sur  article </> }} />
                        </> : <>

                            <DashStatsBox props={{ count: clientsCount, type: <>Clients Inscrits</> }} />
                            <DashStatsBox props={{ count: clientsCount, type: <>Abonements Actifs</> }} />
                            <DashStatsBox props={{ count: simulationCount, type: <>Simulations faites</> }} />
                        </>
                    }
                </div>
            </section>
            <section className="ds-right">
                <h4 className='withIcon'> <PhCalendarBold /> Ces 30 derniers jours</h4>
                <div className='ds-statsList'>
                    {
                        grade == 'Bloger' ? <>
                            <DashStatsBox props={{ count: thirtyDayArticlesCounts, type: <>Articles Publies</> }} />
                            <DashStatsBox props={{ count: thirtyDayArticlesCommentCounts, type: 'Commentaires' }} />
                        </> : <>
                            <DashStatsBox props={{ count: thirtyDayClientCount, type: <>Clients Inscrits</> }} />
                            <DashStatsBox props={{ count: thirtySimulationCount, type: <>Simulations faites</> }} />
                        </>
                    }

                </div>
            </section>
            {
                grade != 'Bloger' && <section className="ds-bottom">
                    <h4 className='withIcon'> <PhChartLineUpBold /> Statistiques des statut de prospect</h4>
                    <div className='ds-statsList'>
                        {
                            clientStatusCount.map((item, ind) => <DashStatsBox key={'client status nb' + ind}
                                props={{ count: item.count, type: item.type }} />)
                        }
                    </div>
                </section>
            }

        </div>
    )
}

export default function DashStatsBox({ props }) {
    const { count, icon, type } = props;
    return (
        <article className='dashStatsBox'>
            <b className='withIcon'>
                <PhUserFill /> {count}
            </b>
            <span>{type}</span>
        </article>
    )
}


export function DashboardLastClientList({ authed }) {
    const { _id, grade } = authed;
    const [lastTenClients, setLTC] = useState([]);
    const dispatch = useDispatch();
    const { showModal } = useModal();

    useEffect(() => {
        let reqUrl = ''
        if (grade == 'Agent') {
            reqUrl = 'agent/' + _id + '/lastTenClients'
        } else {
            reqUrl = 'lastTenClients'
        }
        axios.get('/admin/' + reqUrl)
            .then(res => setLTC(res.data))
            .catch(err => {
                alert('Une erreur est survenue')
            })

    }, [])
    return (
        <div className='dashboard-lastClients'>
            <h2>Liste des 10 derniers clients inscrits</h2>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Nom Client</th>
                            <th>Prenom Client</th>
                            <th>Mail Client</th>
                            <th>Telephone Client</th>
                            <th>Fait le</th>
                            {
                                adminAndAgent.includes(grade) && <th>Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lastTenClients.length > 0 ? <>
                                {
                                    lastTenClients.map((ltc, index) => <tr key={"client sim nb" + index}>
                                        <td>{ltc.nom}</td>
                                        <td>{ltc.prenom}</td>
                                        <td>{ltc.email}</td>
                                        <td>{ltc.telephone}</td>
                                        <td>
                                            {moment(ltc.created_at).format("DD MMM Y H:mm")}
                                        </td>
                                        {
                                            adminAndAgent.includes(grade) && <td>
                                                <button onClick={() => {
                                                    dispatch(setCurrentClientToStore(ltc))
                                                    showModal(<PreviewClient client={ltc}
                                                        agents={[]} authed={authed} />)
                                                }}>Voir</button>
                                            </td>
                                        }
                                    </tr>)
                                }
                            </> : <tr>
                                <td colSpan={5}>Aucune simulation</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}