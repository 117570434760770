import React from 'react'
import { useParams } from 'react-router';
import BlogCategoryManager from '../RoutesSubComponents/blog/category';
import BlogAddArticle from '../RoutesSubComponents/blog/article/add';
import ViewBlogArticle from '../RoutesSubComponents/blog/article/view';
import UpdateBlogArticle from '../RoutesSubComponents/blog/article/update';
import BlogArticlesList from '../RoutesSubComponents/blog/article/list';
import { withAuth } from '../hocs/withAuth';

function Blog() {
    const { action, actionId } = useParams();
    function dispatchBlogComponent() {
        if (action && actionId) {
            if (action == 'view') {
                return <ViewBlogArticle actionId={actionId} />
            }
            return <UpdateBlogArticle actionId={actionId} />
        } else if (action && !actionId) {
            if (action == 'add') {
                return <BlogAddArticle />;
            }
            return <BlogArticlesList />
        } else {
            return <BlogCategoryManager />
        }
    }
    return (
        <div className='blogPart'>
            {/* <PageTitle title={'Gerer votre blog'} /> */}
            {
                dispatchBlogComponent()
            }
        </div>
    )
}

export default withAuth(Blog)