import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PageTitle } from '../GlobalComponents/other';
import GlobalTable from '../RoutesSubComponents/table'
import { withAuth } from '../hocs/withAuth';
import { SectionLoader } from '../uikits/others';

function Prelevements() {
    const [prelevements, setPrelevements] = useState([]);
    const [pLoading, setPloading] = useState(true);


    useEffect(() => {
        axios.get("/form/prelevement/list")
            .then(res => setPrelevements(res.data))
            .catch(err => console.log(err))
            .finally(() => setPloading(false))
    }, [])

    function deleteFunction(prelevementId) {
        axios.delete("/form/prelevement/delete/" + prelevementId)
            .then(res => window.location.reload())
            .catch(err => console.log(err))
    }


    if (pLoading)
        return <SectionLoader />
    return (
        <div className='tablesInfos'>
            {
                prelevements.length > 0 ? <>
                    <PageTitle title="Prelevements SEPA Clients" />
                    <h3>
                        Le lien a envoyer au client pour un prelevement :
                        <a href='https://france-assist.fr/Prelevement/SEPA'>Le lien</a>
                    </h3>
                    <br />
                    <section className='scrollableTableContainer'>
                        <GlobalTable props={{ data: prelevements, typeInfo: "prelevement", deleteFunction }} />
                    </section>

                </> : 'Aucun prelevement'
            }
        </div>
    )
}

export default withAuth(Prelevements)
