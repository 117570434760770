import { SvgSpinners3DotsBounce } from "../GlobalComponents/Icons";

export function FormButton(props) {
    const { type, text, autoWidth, isValid, ...rest } = props;

    function divStyle() {

        if (autoWidth) {
            return {}
        }
        return { width: '80%', margin: '25px auto' }
    }
    return (
        <div style={divStyle()} className="formBtn">
            <button {...props}
                type="submit"
                className={(isValid ? '' : 'btnDisabled')}
                style={{
                    borderRadius: '8px', width: autoWidth ? 'auto' : '100%'
                }}
                disabled={isValid ? false : true}

            >
                {text != '' ? text : <SvgSpinners3DotsBounce />}
            </button>
        </div>
    )
}