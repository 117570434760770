import React from 'react'
import moment from 'moment'
import { ConfirmationMessage } from "../GlobalComponents/Modal"
import { PhEye, PhTrash } from '../GlobalComponents/Icons'
import { useModal } from '../contexts/modal'
import { SectionLoader } from '../uikits/others'


function dispatchDetailsModal(type, details) {
    if (["contact", "prelevement"].includes(type)) {
        return <DetailsModal props={details} />
    }
}
function GlobalTable({ props }) {
    const { data, typeInfo, deleteFunction, otherRow } = props
    const { showModal } = useModal();

    return (
        <table>
            <thead>
                <tr>
                    <th>Mail Client</th>
                    <th>Fait le</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.length ?
                        data.map((dt, index) => <tr key={"client sim nb" + index}>
                            <td>
                                {dt.email}
                            </td>
                            <td>
                                {moment(dt.created_at).format("DD MMM Y")}
                            </td>
                            <td>
                                <button className="viewBtn" onClick={() => {
                                    showModal(dispatchDetailsModal(typeInfo, dt))
                                }}>
                                    <PhEye />
                                </button>


                                <a href={"mailto:" + dt.email}>Répondre</a>


                                <button className="deleteBtn" onClick={() => {
                                    showModal(<ConfirmationMessage props={{
                                        text: "Voulez vous vraiment supprimer le " + typeInfo,
                                        functionAfterConfirm: deleteFunction,
                                        param: dt._id
                                    }} />)
                                }}>
                                    <PhTrash />
                                </button>
                            </td>
                        </tr>) : <SectionLoader />
                }
            </tbody >
        </table >
    )
}


function DetailsModal({ props }) {
    const { email, username, nom, prenom, telephone, address, codepostal, ville, rib, message } = props
    const keyTitle = {
        email: "Email",
        username: "Nom et Prenom", nom: "Nom", prenom: "Prenom",
        address: "Adresse", telephone: "Telephone", codepostal: "Code postal",
        ville: "Ville", rib: "Rib", message: "Message", created_at: "Date de creation"
    }
    function showIfExist() {
        let details = [];
        for (const key in props) {
            if (!["_id", "type", "__v"].includes(key)) {
                details.push(<>
                    <b> {keyTitle[key]} :</b>
                    <span>
                        {
                            key === "created_at" ?
                                moment(props[key]).format('D MMM YYYY')
                                : props[key]
                        }
                    </span>
                </>)
            }
        }
        return details.map((detail, i) => <p key={"le detail" + i}>
            {detail}
        </p>);
    }
    return <div className='modalViewClientInfos'>
        {
            showIfExist()
        }
    </div>
}
export default GlobalTable
