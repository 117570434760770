import React from 'react'
import { useState } from 'react';
import useBlogCategory from '../../../Hooks/blog/useBlogCategory';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { editorBtnsList } from '../../../Rawdata/editor';
import { useAuth } from '../../../contexts/auth';
import { SectionLoader } from '../../../uikits/others';
import { Form, FormikProvider, useFormik } from 'formik';
import { newBlogArticleFields } from '../../../utils/form/fields';
import FormikFieldController from '../../../uikits/form';
import { FormButton } from "../../../uikits/button";

function BlogAddArticle() {
    const { user } = useAuth();
    const auth = user;
    const { blogCategorys, bcLoading } = useBlogCategory();
    const [editorState, setES] = useState('');
    const [operationLoad, setOL] = useState(false);

    const formik = useFormik({
        initialValues: { title: '', banner: '', description: '', categoryId: '' },
        onSubmit: handleSubmit,
        validateOnMount: true
    });
    const { isValid, setFieldValue, isSubmitting } = formik;

    function handleSubmit(formValues) {
        setOL(true)
        const formdata = new FormData();
        formdata.append('banner', formValues.banner);
        formdata.append('title', formValues.title);
        formdata.append('description', formValues.description);
        formdata.append('categoryId', formValues.categoryId);
        formdata.append('content', '<div  class="editorContentBox" >' + editorState + '</div>');
        formdata.append('autorId', auth._id);

        axios.post('/admin/blog/article/add', formdata)
            .then(res => {
                console.log(res.data);
                if (res.data._id) {
                    window.location = '/Blog/article/view/' + res.data._id
                }
            }).catch(err => {
                alert('Une erreur est survenue');
            }).finally(() => setOL(false))
    }

    if (bcLoading)
        return <SectionLoader />;
    if (blogCategorys.length == 0)
        return <p>Aucune categorie de blog</p>
    return (
        <div>
            <FormikProvider value={formik}>
                <Form>
                    {
                        newBlogArticleFields(blogCategorys).map((item, i) => <FormikFieldController
                            key={'article field nb' + i}
                            {...item}
                        />)
                    }
                    <SunEditor placeholder="Votre article ici..."
                        setContents={editorState}
                        onChange={setES}
                        setOptions={{
                            buttonList: editorBtnsList,
                            minHeight: '50vh'
                        }}
                    />
                    <FormButton text={isSubmitting ? "" : "Ajouter"}
                        isValid={isSubmitting ? false : isValid}
                    />
                </Form>

            </FormikProvider>
        </div>
    )
}

export default BlogAddArticle