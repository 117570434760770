import { mapSimulationObj, mapSimulationResult } from "../../../../utils/simulation.js"


export const ClientSimulations = ({ simulations }) => {

    return <section className="cs-liste">
        {
            simulations.length > 0 ?
                simulations.map((simulation, index) => <div key={"ct simulation nb" + index}>
                    <section>
                        <h3>Fiche de la Simulation n* {index + 1} </h3>
                        {
                            mapSimulationObj(simulation)
                        }
                    </section>
                    <section>
                        <h3>Resultat de la Simulation * {index + 1} </h3>
                        {
                            mapSimulationResult(simulation.aides)
                        }
                    </section>
                </div>)
                : <p className="notFoundTxt">Ce client n'a pas fait de simulations</p>
        }
    </section>
}
 // 'partner_status',
 //                'personal_status', 'recoit_deja', 