import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./Store";
import * as serviceWorker from './serviceWorker';
import { AuthContextProvider } from './contexts/auth';
import { ModalProvider } from './contexts/modal';


ReactDOM.render(<>
  <BrowserRouter>
    <AuthContextProvider>
      <ModalProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ModalProvider>
    </AuthContextProvider>
  </BrowserRouter>
</>, document.getElementById('root'));

reportWebVitals();
serviceWorker.unregister();