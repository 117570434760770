import moment from "moment";
export function mapSimulationObj(simulationObj) {
    let allSimulationsInfos = [];
    for (const key in simulationObj) {
        if (!['aides', 'simulationJwt', 'children', '_id', '__v', 'createdAt', 'updatedAt'].includes(key)) {
            if (Array.isArray(simulationObj[key])) {
                allSimulationsInfos.push(<>
                    <>
                        <b>{key} </b>  : {
                            simulationObj[key].map((ob, oi) => JSON.stringify(ob) + ',')
                        }
                    </>
                    <br />
                </>)
            }
            else {
                allSimulationsInfos.push(<>
                    <>
                        <b>{key} </b>  : {
                            key == "created_at" ? moment(simulationObj[key]).format("DD MMM Y") : simulationObj[key]
                        }
                    </>
                    <br />
                </>)
            }

        }

        if (key == 'children') {
            allSimulationsInfos.push(<>
                <>
                    <b>Enfants</b>
                    <ul >
                        {
                            simulationObj[key].map((child, oi) => <li>
                                <h3>Enfant n* {oi + 1} </h3>
                                <p>
                                    <span>Age : {child.age}</span>
                                </p>
                                <p>
                                    <span>Echelon de bourse : {child.echelonDeBourse ? child.echelonDeBourse : 0}</span>
                                </p>
                                <p>
                                    <span>Revenue : {child.revenu ? child.revenu : 0}</span>
                                </p>
                                <p>
                                    <span>Boursier : {child.boursier ? child.boursier : 0}</span>
                                </p>
                                <p>
                                    <span>Taux de handicap : {child.tauxHandicap ? child.tauxHandicap : 0}</span>
                                </p>
                            </li>)
                        }

                    </ul>
                </>

            </>)
        }
    }

    return allSimulationsInfos.map((asi, index) => <p key={"asi nb" + index}> {asi} </p>);
}


export function mapSimulationResult(aidesObj) {
    const allAides = []
    const allNewAides = []
    for (const key in aidesObj) {
        if (key != "newAides" && key != 'mj') {
            allAides.push(<>
                <b>{key} </b>  : {aidesObj[key]}
            </>)
        }

    }

    if (aidesObj.newAides && aidesObj.newAides) {
        for (const key in aidesObj.newAides) {
            allNewAides.push(<>
                <b>{key} </b>  : {aidesObj.newAides[key]}
            </>)

        }
    }
    return <>
        {allAides.map((ad, index) => <p key={"aide siml nb" + index}> {ad} </p>)}
        {allNewAides.map((ad, index) => <p key={"aide siml nb" + index}> {ad} </p>)}
    </>
}

export function notExtractSpecificObjValueFromFullOBj(fullObj, specificValueKeys) {
    // ['nom', 'prenom','email','phone','_id', '__v','aides','created_at']
    const finalObj = {}
    for (const key in fullObj) {
        if (specificValueKeys.includes(key)) {
        } else {
            finalObj[key] = fullObj[key]
        }
    }
    return finalObj;
}

export function extractSpecificObjValueFromFullOBj(fullObj, specificValueKeys) {
    // ['nom', 'prenom','email','phone','_id', '__v','aides','created_at']
    const finalObj = {}
    for (const key in fullObj) {
        if (specificValueKeys.includes(key)) {
            finalObj[key] = fullObj[key]
        }
    }
    return finalObj;
}




