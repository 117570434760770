import axios from 'axios'
import React from 'react'
import { ConfirmationMessage } from '../../GlobalComponents/Modal'
import { useModal } from '../../contexts/modal'

function DeleteAide({ props }) {
    const { currentAide, refetchAides } = props
    const { hideModal } = useModal();

    function deleteAide() {
        axios.post("/admin/aide/delete", { aideId: currentAide._id })
            .then((res) => {
                refetchAides()
                hideModal()
            })
            .catch((err) => console.log(err))
    }
    return (
        <ConfirmationMessage props={{ text: "Voulez vous vraiment supprimez cette aide ?", functionAfterConfirm: deleteAide }} />
    )
}

export default DeleteAide
