import React from 'react'
import { SimpleImage } from './Img'
import { Link } from 'react-router-dom'

export function PageTitle({ title }) {
    return (
        <div className='pageTitle'>
            <h1>{title}</h1>
        </div>
    )
}


export function SearchDataAndAddNewDataSection({ onSearch, newDataAction, isButton }) {
    return (
        <div className="searchAndAddNewItemSection">
            <section style={(isButton && isButton == 'searchFullWidth') ? { width: '100%' } : {}} >
                <input type={"search"} placeholder="Rechercher client ici"
                    onChange={onSearch} />
            </section>
            {
                (isButton && isButton != 'searchFullWidth') && isButton
            }
            {
                (!isButton) && <Link to={newDataAction.addLink}>{newDataAction.btnMessage}</Link>
            }
        </div>
    )
}
export function PageLoader() {
    return <div className='pageLoader'>
        <SimpleImage props={{ src: "loader.svg", alt: 'app loader' }} />
    </div>
}