import React from 'react'
import { useState } from "react";
import axios from "axios";

import { UserFormFields } from '../userFormUI';
import { PageLoader } from '../../GlobalComponents/other';
import { useAuth } from '../../contexts/auth';
import { FormikProvider, Form, useFormik } from 'formik';
import { FormButton } from "../../uikits/button";
import { NewClientByAdminSchema, NewClientSchema } from '../../utils/form/validations';

function CreationClient() {
    const { user } = useAuth();
    const auth = user;
    const formik = useFormik({
        initialValues: {
            nom: "", prenom: "",
            codepostal: "", telephone: "", email: "",
            password: '', password_confirmation: '', agentId: ""
        },
        onSubmit: handleSubmit,
        validationSchema: auth.grade === 'Admin' ? NewClientByAdminSchema : NewClientSchema,
        validateOnMount: true
    });
    const { isSubmitting, setSubmitting, isValid } = formik;
    const [creationStatus, setCreationStatus] = useState('')


    const allAgents = user.otherAdmins.filter((item => item.grade == 'Agent'))


    function handleSubmit(formValues) {
        let body = {
            "nom": formValues.nom, "prenom": formValues.prenom,
            "telephone": formValues.telephone, "email": formValues.email,
            "codepostal": formValues.codepostal,
            "password": formValues.password,
            "agentId": auth.grade === 'Admin' ? formValues.agentId.trim() : auth._id.trim()
        }
        if (auth.grade === 'Admin') {
            const copy = [...allAgents];
            const findAgent = copy.filter(ag => ag._id === formValues.agentId)
            if (findAgent.length === 0) {
                return false;
            }

        }
        // console.log(body);
        axios.post("/user/register", body)
            .then(res => {
                if (res.data.userId) {
                    setCreationStatus(<AddOrUpdateStatus props={{
                        type: "good",
                        text: "Client crée avec succes",
                    }} />)
                }
            })
            .then(err => console.log(err))
            .finally(() => setSubmitting(false))
    }
    return (
        <div className='createClient'>
            <h1>Créer un client</h1>
            {
                allAgents.length > 0 ? <>
                    <FormikProvider value={formik}>
                        <Form autocomplete="new-password">
                            <UserFormFields typeFields="newClient"
                                grade={auth.grade}
                                allAgents={allAgents}
                            />
                            <FormButton text={isSubmitting ? "" : "Soumettre"}
                                isValid={isSubmitting ? false : isValid} />
                        </Form>
                    </FormikProvider>
                    {
                        creationStatus
                    }
                </> : <PageLoader />
            }
        </div>
    )
}


const AddOrUpdateStatus = ({ props }) => {
    const { type, text, other } = props;
    return <div className='addOrUpdateStatus'>
        <b className={type + "Status"}>{text}</b>
        {other && other}
    </div>
}
export default CreationClient;

