import { SimpleImage } from "../GlobalComponents/Img";
import axios from "axios"
// import { setAuthedGeneralInfo, setIsAuthed } from "../Store/actions/authActions";
import { Form, FormikProvider, useFormik } from "formik";
import { loginFields } from "../utils/form/fields";
import FormikFieldController from "../uikits/form";
import { FormButton } from "../uikits/button";
import { withoutAuth } from "../hocs/withoutAuth";
import { useAuth } from "../contexts/auth";
import { LoginSchema } from "../utils/form/validations";

const Accueil = () => {
    const { login } = useAuth();

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        onSubmit: handleSubmit,
        validateOnMount: true,
        validationSchema: LoginSchema
    });
    const { isValid, isSubmitting, setSubmitting } = formik;

    async function handleSubmit(formValues) {
        try {
            let authUser = await axios.post('/user/login',
                {
                    email: formValues.email.trim(),
                    password: formValues.password.trim()
                })
            const { token } = authUser.data;
            login(token)
        } catch (error) {
            alert(error.response.data.msg)
            // alert('url')
        } finally {
            setSubmitting(false)
        }
    }

    return <div className="accueil">
        <section className="a-left">

            <h1>
                Espace <br /> Administration
            </h1>
        </section>
        <section className="a-right">
            <SimpleImage props={{ src: "logo.png", alt: "Home log logo" }} />
            <FormikProvider value={formik}>
                <Form>
                    {
                        loginFields.map((item, i) => <FormikFieldController {...item}
                            key={'login fields nb' + i} />)
                    }
                    <FormButton
                        text={isSubmitting ? '' : "Se connecter"}
                        isValid={isSubmitting ? false : isValid}
                    />
                </Form>
            </FormikProvider>
        </section>
    </div>
}

export default withoutAuth(Accueil);