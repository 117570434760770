import { useEffect, useState } from "react"
import axios from "axios";

const useAdminAndAgents = () => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(async () => {
        try {
            const allDatas = await axios.get('/user/all-agents-and-admin');
            setDatas(allDatas.data);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return {
        adminAndAgents: datas,
        adminAndAgentsLoading: loading, refetchDatas
    };
}

export default useAdminAndAgents;