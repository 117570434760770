import UpdateAide from './upAide';
import DeleteAide from './deleteAide'
import AddAide from './addAide';
import { useAides } from './hooks/useAides';
import { PhNotePencil, PhTrash } from '../../GlobalComponents/Icons';
import { useModal } from '../../contexts/modal';
import { SectionLoader } from '../../uikits/others';

function AidesList() {
    const { aides, aLoading, refetchAides } = useAides();
    const { showModal } = useModal();

    function showAddAideModal() {
        showModal(<AddAide props={{ refetchAides }} />)
    }
    function showUpdateAideModal(aide) {
        showModal(<UpdateAide props={{ currentAide: aide, refetchAides }} />)
    }
    function showDeleteAideModal(aide) {
        showModal(<DeleteAide props={{
            currentAide: aide, refetchAides
        }} />)
    }

    if (aLoading)
        return <SectionLoader />
    return (<>
        <div className='aides-new'>
            <button onClick={() => {
                showAddAideModal()
            }}>Ajouter une nouvelle aide</button>
        </div>
        <section className='aides-liste'>
            <h1>Liste des aides</h1>
            {
                aides.map((aide, index) => <article key={"aide nb" + index} className="dataBox">
                    <p>{aide.title}</p>
                    <div className='dataBoxActions'>
                        <PhTrash onClick={() => showDeleteAideModal(aide)} />
                        <PhNotePencil onClick={() => showUpdateAideModal(aide)} />
                    </div>
                </article>)
            }
        </section>
    </>
    )
}

export default AidesList
