import { useEffect } from "react";
import { useContext, createContext, useState } from "react";
import { useLocation } from "react-router";

export const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (modalVisible) {
            hideModal()
        }
    }, [location.pathname]);

    function showModal(content) {
        setModalContent(content);
        setModalVisible(true);
    }

    function hideModal() {
        setModalContent(null);
        setModalVisible(false);
    }
    return <ModalContext.Provider value={{
        modalVisible, modalContent,
        showModal, hideModal
    }}>
        {children}
    </ModalContext.Provider>
}

export function useModal() {
    return useContext(ModalContext);
}