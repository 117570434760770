import { SvgSpinnersBarsScale } from "../GlobalComponents/Icons";

export function FullScreenLoader() {
    return <div className="fullScreenLoader">
        <SvgSpinnersBarsScale />
    </div>
}

export function SectionLoader() {
    return <div className="sectionLoader">
        <SvgSpinnersBarsScale />
    </div>
}

export function PageNavigator({ currentPage, setCurrentPage, datas }) {
    return <section className='pageNavigator'>
        {currentPage > 1 && <button onClick={() => setCurrentPage(prev => prev - 1)}>Prev</button>}
        {currentPage < datas.totalPages && <button onClick={() => setCurrentPage(prev => prev + 1)}>Next</button>}
        {datas.page + ' Sur ' + datas.totalPages + " pages"}
    </section>;
}