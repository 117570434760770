import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { ConfirmationMessage } from "../../../GlobalComponents/Modal";
import { ClientInfosModal } from "../modals/clientInfosDetails";
import UpdateClientDataModal from "../modals/updateClientData";
import { currentRefetcherSelector } from "../../../Store/selectors/refetcherSelector";
import { IcBaselineDelete, IcBaselineEdit, PhEye } from "../../../GlobalComponents/Icons";
import { useModal } from "../../../contexts/modal";


function ClientInfosTable({ props }) {
    const dataRefresh = useSelector(currentRefetcherSelector)

    const { infoType, infos, firstColumnTitle, firstColumnKey } = props;
    const { showModal, hideModal } = useModal();

    function makeDelete(infoId) {
        let reqUrlEnd = "";
        if (infoType == "note") {
            reqUrlEnd = "/notes/delete"
        } else if (infoType == "transaction") {
            reqUrlEnd = "/transactions/delete"
        } else if (infoType == "email") {
            reqUrlEnd = "/admin/email/delete"
        } else if (infoType == "document") {
            reqUrlEnd = "/admin/documents/delete"
        } else if (infoType == "password") {
            reqUrlEnd = "/clientPwd/delete"
        }
        axios.post(reqUrlEnd, { id: infoId })
            .then(res => {
                hideModal()
                dataRefresh()
            })
            .catch(err => console.log(err))
            .finally(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            })

    }
    function confirmInfoDeletion(info) {
        showModal(<ConfirmationMessage props={{
            text: "Voulez vous vraiment supprimez cette donnée ?",
            functionAfterConfirm: makeDelete, param: info._id
        }} />)
    }

    function viewInfoDetail(info) {

        showModal(<ClientInfosModal props={{ clientInfos: info }} />)
    }

    function showUpdateModal(info) {

        showModal(<UpdateClientDataModal
            props={{
                currentClientInfo: info, navValue: infoType + 's',
                dataRefresh
            }} />)
    }


    function dispatchNotFoundText(type) {
        if (["document", "email"].includes(type)) {
            return " " + type + " repertorié"
        } else if (type === "password") {
            return "Aucun mot de passe repertorié"
        } else {
            return " " + type + " repertoriée"
        }
    }

    return (
        <>
            <table className="clientTable">
                <thead>
                    <tr>
                        <th className="ct-5">*</th>
                        <th>{firstColumnTitle}</th>
                        <th className="ct-20">Date</th>
                        <th className="ct-20">Actions</th>
                    </tr>

                </thead>
                <tbody>
                    {
                        infos.length > 0 ?
                            infos.map((info, index) => <tr key={infoType + "-info nb" + index}>
                                <td className="ct-5" colSpan={1}>
                                    {index + 1}
                                </td>
                                <td colSpan={1}>
                                    {info[firstColumnKey]}
                                </td>
                                <td className="ct-20" colSpan={1}>
                                    {moment(info.created_at).format(" Do MMM Y")}
                                </td>
                                <td className="ct-20" colSpan={1}>
                                    <button className="viewBtn"
                                        onClick={() => viewInfoDetail(info)}>
                                        <PhEye />
                                    </button>
                                    {
                                        ["note", "transaction", "password"].includes(infoType)
                                        && <button className="updateBtn"
                                            onClick={() => showUpdateModal(info)}>
                                            <IcBaselineEdit />
                                        </button>
                                    }
                                    <button className="deleteBtn"
                                        onClick={() => confirmInfoDeletion(info)}>
                                        <i className="mdi mdi-delete"></i>
                                        <IcBaselineDelete />
                                    </button>
                                </td>
                            </tr>)
                            : <tr>
                                <td colSpan={4}>
                                    <p className="notFoundTxt">
                                        {
                                            dispatchNotFoundText(infoType)
                                        }
                                    </p>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default ClientInfosTable
