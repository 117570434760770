import { useEffect, useState } from "react"
import axios from "axios";

const useAllReviewsLikeTrustpilot = () => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);
    useEffect(async () => {
        try {
            const allDatas = await axios.get('/avis/like-trustpilot/all')
            setDatas(allDatas.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return { reviews: datas, refreshReviews: refetchDatas };
}

export default useAllReviewsLikeTrustpilot;