import React from 'react'
import ConfirmPassword from './confirmPassword';
import { useAuth } from '../../contexts/auth';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormButton } from '../../uikits/button';
import { updateGenericAccountProfilFields } from '../../utils/form/fields';
import FormikFieldController from '../../uikits/form';
import { useModal } from '../../contexts/modal';


function UpdateProfilForm() {
    const { showModal } = useModal();
    const { user } = useAuth();
    const userConnected = user;
    const formik = useFormik({
        initialValues: {
            nom: userConnected.nom, prenom: userConnected.prenom, address: userConnected.address,
            codepostal: userConnected.codepostal, telephone: userConnected.telephone, email: userConnected.email,
            password: '', password_confirmation: ''
        },
        onSubmit: handleSubmit
    });
    function handleSubmit(formValues) {
        showModal(<ConfirmPassword formValues={formValues} />)
    }

    return (
        <FormikProvider value={formik}>
            <Form>
                {
                    updateGenericAccountProfilFields.map((item, i) => <FormikFieldController {...item}
                        key={'update account fields nb' + i} />)
                }
                <FormButton
                    text="Sauvegarder les informations"
                    isValid={true}
                />
            </Form>
        </FormikProvider>
    )
}

export default UpdateProfilForm;
