import { useEffect, useState } from "react"
import axios from "axios";

const useDocument = (clientId, initialdocument = []) => {
    const [documents, setDocuments] = useState(initialdocument);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const alldocuments = await axios.get('/admin/emails/' + clientId)
            setDocuments(alldocuments.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchDocuments = () => {
        setRefecth(true)
    };

    return { documents, refetchDocuments };
}

export default useDocument;