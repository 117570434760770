import { mapSimulationObj, mapSimulationResult } from "../../utils/simulation";
import useAdminAndAgents from "../../Hooks/useAdminAndAgents";


function DetailsSimulationsModal({ simulation }) {
    const { adminAndAgents, adminAndAgentsLoading } = useAdminAndAgents();

    function getCurrentAgent(agentList, agentId) {
        const filter = agentList.filter(item => item._id == agentId);
        if (filter)
            return filter[0].nom + ' ' + filter[0].prenom;
        return 'Aucun agent';
    }
    return <div className='simulation-detail'>
        <section style={{ marginTop: '20px' }}>
            <h3>Fiche de la Simulation  </h3>
            <p>
                <b>
                    Agent :
                </b>
                {
                    !adminAndAgentsLoading && getCurrentAgent(adminAndAgents, simulation.agentId)
                }
            </p>
            {
                mapSimulationObj(simulation)
            }
        </section>
        <section>
            <h3>Resultat de la Simulation  </h3>
            {
                mapSimulationResult(simulation.aides)
            }
        </section>
    </div>
}


export default DetailsSimulationsModal;