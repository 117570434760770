import { combineReducers } from "redux";
import { createStore } from "redux";
import { clientReducer } from "./reducers/clientReducer";
import { refetcherReducer } from "./reducers/refetcher";

const store = createStore(combineReducers(
    {
        client: clientReducer,
        refetcher: refetcherReducer
    }
),
    window.__REDUX_DEVTOOLS_EXTENSION__
    && window.__REDUX_DEVTOOLS_EXTENSION__())

// store.subscribe(() => console.log(store.getState()))
export default store;