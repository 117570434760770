import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import { confirmUpdateWithPasswordsFields } from "../../utils/form/fields";
import FormikFieldController from "../../uikits/form";
import { FormButton } from "../../uikits/button";
import { useModal } from "../../contexts/modal";
import { errorToast, successToast } from "../../utils/alerts";
import { PassSchema } from "../../utils/form/validations";

const ConfirmPassword = (props) => {
    const { hideModal } = useModal();
    const { formValues } = props;
    const formik = useFormik({
        initialValues: { password: '', password_confirmation: '' },
        validateOnMount: true,
        validationSchema: PassSchema,
        onSubmit: handleSubmit
    });
    const { isValid, isSubmitting } = formik;
    async function handleSubmit(formikValues) {
        try {
            await axios.post('/user/confirmation', { confirm: formikValues.password })
            try {
                const stringifyFormValues = {
                    nom: formValues.nom, prenom: formValues.prenom,
                    email: formValues.email, codepostal: formValues.codepostal,
                    address: formValues.address, telephone: formValues.telephone,
                }
                if (formValues.password) {
                    stringifyFormValues.password = formValues.password;
                }
                await axios.put('/user/update', { info: stringifyFormValues });
                successToast('Tout est ok!')

            } catch (error) {
                errorToast('Vous devez definir un nouveau mot de passe ');
            } finally {
                hideModal()
            }
        } catch (error) {
            errorToast('Mot de passe invalide');
            console.log(error)
        }


    }
    return <FormikProvider value={formik}>
        <Form>
            {
                confirmUpdateWithPasswordsFields.map((item, i) => <FormikFieldController {...item}
                    key={'conf update account fields nb' + i} />)
            }
            <FormButton
                text={isSubmitting ? "" : "Confirmer l'operation"}
                isValid={isSubmitting ? false : isValid}
            />
        </Form>
    </FormikProvider>
}

export default ConfirmPassword;