import axios from 'axios'
import React, { useState } from 'react'
import { useModal } from '../../contexts/modal';
function UpdateAide({ props }) {
    const { currentAide, refetchAides } = props;
    const [aideTitle, setAideTitle] = useState(currentAide.title)
    const { hideModal } = useModal();

    function handleSubmit(event) {
        event.preventDefault();
        axios.put('/admin/aide/update', { aideId: currentAide._id, newTitle: aideTitle })
            .then((res) => {
                refetchAides()
                hideModal();
            })
            .catch((err) => console.log(err))

    }
    return (
        <div>
            <form onSubmit={(event) => handleSubmit(event)}>
                <div className='formSegment'>
                    <label>Titre de l'aide</label>
                    <input type={"text"} placeholder="RSA,AAH,etc..."
                        onChange={(event) => {
                            setAideTitle(event.target.value)
                        }} value={aideTitle} />
                </div>
                <div className='formBtn'>
                    <button>Enregistrer</button>
                </div>
            </form>
        </div>
    )
}

export default UpdateAide
