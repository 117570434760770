import { ConfirmationMessage } from "../../GlobalComponents/Modal"

export const UpdateConfirmation = ({ props }) => {
    const { user, toggleGrade } = props;

    function functionAfterConfirm() {
        toggleGrade(user)
    }
    return <ConfirmationMessage props={{
        text: user.grade == 'Agent' ? <>
            Voulez vous vraiment <b>
                "rendre administrateur principal"</b> ce compte ?
        </> : <>
            Voulez vous vraiment <b>
                "retrograder"</b> ce compte ?
        </>,
        functionAfterConfirm
    }} />
}

export const DeleteConfirmation = ({ props }) => {
    const { user, deleteUser } = props;

    function functionAfterConfirm() {
        deleteUser(user)
    }
    return <ConfirmationMessage props={{
        text: <>
            Voulez vous vraiment <b>"supprimer"</b> ce compte ?</>,
        functionAfterConfirm

    }} />
}

