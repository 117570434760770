
import { PhAddressBook, PhAirplay, PhArrowsHorizontalLight, PhBellSimple, PhFiles, PhLockKey, PhNotePencil } from "../GlobalComponents/Icons"
export const clientNavigationRadio = [
    {
        label: 'Ressources', value: "ressources", icon: <PhAddressBook />
    },
    {
        label: 'Notes', value: "notes", icon: <PhNotePencil />
    },
    {
        label: 'Emails', value: "emails", icon: <PhBellSimple />
    },
    {
        label: 'Document', value: "documents", icon: <PhFiles />
    },
    {
        label: 'Transactions', value: "transactions", icon: <PhArrowsHorizontalLight />
    },
    {
        label: 'Mot de passe', value: "passwords", icon: <PhLockKey />
    },
    {
        label: 'Simulations', value: "simulations", icon: <PhAirplay />
    },
]