import axios from 'axios'
import React, { useState } from 'react'
import { useModal } from '../../contexts/modal';

function AddAide({ props }) {
    const { refetchAides } = props;
    const [aideTitle, setAideTitle] = useState('');
    const { hideModal } = useModal();

    function handleSubmit(event) {
        event.preventDefault();

        axios.post('/admin/aide/add', { title: aideTitle })
            .then((res) => {
                refetchAides()
                hideModal()
            })
            .catch((err) => console.log(err))
    }
    return (
        <div>
            <form onSubmit={(event) => handleSubmit(event)}>
                <div className='formSegment'>
                    <label>Titre de l'aide</label>
                    <input type={"text"} placeholder="RSA,AAH,etc..."
                        onChange={(event) => {
                            setAideTitle(event.target.value)
                        }} />
                </div>
                <div className='formBtn'>
                    <button>Ajouter cette aide</button>
                </div>
            </form>
        </div>
    )
}

export default AddAide
