
import ClientInfosTable from "../table";

export const ClientTransactions = ({ transactions }) => {
    return <ClientInfosTable props={{
        infoType: "transaction", infos: transactions,
        firstColumnTitle: "Aide", firstColumnKey: "aide",
        actionsBtns: "up,delete"
    }} />

}