import { InputPasswordWithFormik } from "./password";
import { SimpleInputWithFormik, TextAreaWithFormik } from "./simple";
import { InputCheckBox2WithFormik, InputCheckBoxWithFormik, InputRadioWithFormik, SelectWithFormik } from "./choices";
import { DocFileInputWithFormik, DocFilesInputWithFormik } from "./others";

function FormikFieldController(props) {
    const simplesType = ['text', 'email', 'number', 'tel', 'date'];
    const { fieldType, conditionalFieldName, conditionalValues, ...rest } = props;
    return <>
        {
            (() => {
                if (simplesType.includes(fieldType)) {
                    return <SimpleInputWithFormik {...rest} type={fieldType} />
                }
                if (fieldType == 'password')
                    return <InputPasswordWithFormik  {...rest} />

                if (fieldType === 'checkbox') {
                    return <InputCheckBoxWithFormik {...rest} />
                }
                if (fieldType === 'checkbox2') {
                    return <InputCheckBox2WithFormik {...rest} />
                }
                if (fieldType === 'radio') {
                    return <InputRadioWithFormik {...rest} />
                }
                if (fieldType === 'select') {
                    return <SelectWithFormik {...rest} />
                }
                if (fieldType === 'file') {
                    return <DocFileInputWithFormik {...rest} />
                }
                if (fieldType === 'files') {
                    return <DocFilesInputWithFormik {...rest} />
                }
                if (fieldType === 'textarea') {
                    return <TextAreaWithFormik {...rest} />
                }
            })()
        }
    </>
}

export default FormikFieldController;