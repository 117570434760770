import React from 'react'
import useBlogCategory from '../../../Hooks/blog/useBlogCategory';
import AddBlogCategory from './add'
import BlogCategoryList from './list'
function BlogCategoryManager() {
    const { blogCategorys, bcLoading, refetchDatas } = useBlogCategory();

    if (bcLoading)
        return 'Chargement des categories'
    return (
        <div>
            <AddBlogCategory props={{ refetchBC: refetchDatas }} />
            <BlogCategoryList props={{
                blogCategorys, refetchBC: refetchDatas
            }} />
        </div>
    )
}

export default BlogCategoryManager