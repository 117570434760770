import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { currentRefetcherSelector } from "../../../Store/selectors/refetcherSelector";
import { ClientInfosTypeFields } from "../helpers/infosFormUI";
import { useModal } from "../../../contexts/modal";
import { Form, FormikProvider, useFormik } from "formik";
import { FormButton } from "../../../uikits/button";
import { errorToast, successToast } from "../../../utils/alerts";

const UpdateClientDataModal = ({ props }) => {
    const { hideModal } = useModal();
    const dataRefetcher = useSelector(currentRefetcherSelector)
    const { currentClientInfo, navValue, dataRefresh } = props;
    const formik = useFormik({
        initialValues: {
            title: currentClientInfo.title || currentClientInfo.titre,
            sujet: currentClientInfo.sujet,
            message: currentClientInfo.data,
            aide: currentClientInfo.aide,
            montant: currentClientInfo.montant,
            phase: currentClientInfo.phase,
            note: currentClientInfo.data,
            date: moment(currentClientInfo.date).format('YYYY-MM-DD'),
            time: currentClientInfo.time,
            file: "",

            structure: currentClientInfo.structure,
            numero_secu: currentClientInfo.numero_secu,
            password: currentClientInfo.password
        },
        onSubmit: handleSubmit
    });
    const { isValid, isSubmitting } = formik;


    function handleSubmit(formValues) {
        let reqUrl = '';
        let reqBody = '';
        if (navValue == "emails") {
            reqUrl = '/admin/email';
            reqBody = new FormData();
        } else if (navValue == "notes") {
            reqUrl = '/notes/edit/' + currentClientInfo._id;
            reqBody = {
                data: formValues.note,
                date: formValues.date,
                title: formValues.title,
                time: formValues.time
            }

        } else if (navValue == "transactions") {
            reqUrl = '/admin/transaction/edit/' + currentClientInfo._id;
            reqBody = {
                aide: formValues.aide,
                montant: formValues.montant,
                phase: formValues.phase,
                titre: formValues.title,
                created_at: new Date()
            }
        } else if (navValue == "passwords") {
            reqUrl = '/clientPwd/edit/' + currentClientInfo._id;
            reqBody = {
                numero_secu: formValues.numero_secu,
                structure: formValues.structure,
                password: formValues.password,
            }
        }

        if (["notes", "emails", "transactions", "passwords"].includes(props.navValue)) {
            axios.put(reqUrl, reqBody)
                .then((res) => {
                    hideModal()
                    dataRefetcher()
                    successToast('Operation effectuée!')
                    dataRefresh()
                })
                .catch((err) => {
                    console.log(err);
                    errorToast('Erreur survenue !')
                })
        }


    }

    return <div className="clientInformationAddModal">
        <h3>Modification </h3>
        <FormikProvider value={formik}>
            <Form >
                <ClientInfosTypeFields props={{
                    infosType: navValue
                }} />
                <FormButton
                    text="Enregistrer"
                    isValid={isSubmitting ? false : true}
                />
            </Form>
        </FormikProvider>
    </div>
}


export default UpdateClientDataModal;