import { useEffect, useState } from "react"
import axios from "axios";

const usePasswords = (clientId, initialdocument = []) => {
    const [passwords, setPasswords] = useState(initialdocument);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const allPasswords = await axios.get('/clientPwd/list/' + clientId)
            setPasswords(allPasswords.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchPasswords = () => {
        setRefecth(true)
    };

    return { passwords, refetchPasswords };
}

export default usePasswords;