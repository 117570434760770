import { Field, useField } from "formik";


export function InputRadioWithFormik(props) {
    const { name, choices, label, isDisabled, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    let parsedValue = ["true", "false"].includes(value) ? JSON.parse(value) : value;

    return <div className={"formSegment flex f-column"} >
        <label>{label}</label>
        <section className="fs-radio_checkbox-row f-wrap">
            {
                choices.map((ffrg, index) => <label
                    htmlFor={name + '-' + index}
                    className={"formFieldRadio2 "}
                    key={'ffrg ' + index}>

                    <Field type="radio" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={parsedValue === ffrg.value}
                        disabled={isDisabled ? true : false}
                    />
                    <span>  {ffrg.label}</span>
                </label>)
            }
        </section>
    </div >
}

export function InputCheckBoxWithFormik(props) {
    const { name, choices, label, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;

    return <div className={"formSegment flex f-column"} >
        <label>{label}</label>
        <section className="flex f-column">
            {
                choices.map((ffrg, index) => <article
                    className={"formFieldCheckbox "}
                    key={'ffrg ' + index}>

                    <Field type="checkbox" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={value.includes(ffrg.value)} />
                    <label htmlFor={name + '-' + index}
                        className="flex ">
                        <span>
                            {value.includes(ffrg.value) && 'x'}
                        </span>
                        <p>{ffrg.label}</p>
                    </label>
                </article>)
            }
        </section>
    </div>;
}

export function InputCheckBox2WithFormik(props) {
    const { name, choices, label, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    function handleCheckboxChange(e, ffrg) {
        setValue({ ...value, [ffrg.value]: JSON.stringify(e.target.checked) })
    }

    return <div className={"formSegment formSegmentCheckbox flex f-column"} id="checkBoxSegment" >
        <label>{label}</label>
        <section className="flex f-column">
            {
                choices.map((ffrg, index) => <label
                    htmlFor={name + '-' + index}
                    className={"flex "}
                    key={'ffrg ' + index}>

                    <input type="checkbox" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={value[ffrg.value] == true || value[ffrg.value] == "true"}
                        // checked={value[ffrg.value]}
                        onChange={(e) => handleCheckboxChange(e, ffrg)} />

                    <span>{ffrg.label}</span>

                </label>)
            }
        </section>
    </div>
}

export function SelectWithFormik(props) {
    const { name, options, optionsLabelKey, optionsValueKey, label, ...rest } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    return <div className="formSegment">
        <label>{label}</label>
        <section>
            <select name={name}
                defaultValue={value}
                onChange={(e) => setValue(e.target.value)}
            >
                {
                    options.map((op, index) => <option value={op[optionsValueKey]}
                        key={name + "-select op nb" + index}
                        selected={op[optionsValueKey] == value}
                    >
                        {op[optionsLabelKey]}
                    </option>)
                }
            </select>
        </section>
    </div>
}