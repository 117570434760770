import { PageTitle } from '../GlobalComponents/other';
import UpdateProfilForm from "../RoutesSubComponents/profil/updateProfil";
import { withAuth } from '../hocs/withAuth';

export const Profil = () => {

    return <div className="profil">
        <PageTitle title="Modifiez mon profil" />
        <UpdateProfilForm />
    </div>
}



export default withAuth(Profil);