import axios from "axios";
import { useModal } from "../../contexts/modal";
import { Form, FormikProvider, useFormik } from "formik";
import { newAdminFields } from "../../utils/form/fields";
import FormikFieldController from "../../uikits/form";
import { gradeOptions } from "../../utils/form/others";
import { NewAdminSchema } from "../../utils/form/validations";
import { FormButton } from "../../uikits/button";
import { errorMessageManager, successToast } from "../../utils/alerts";

export const AddAgent = ({ refetchDatas }) => {
    const formik = useFormik({
        initialValues: {
            nom: "", prenom: "", email: "", grade: '',
            address: "", codepostal: '', telephone: "",
            lien: "", password: "", password_confirmation: ""
        },
        onSubmit: handleSubmit,
        validationSchema: NewAdminSchema,
        validateOnMount: true
    });
    const { isSubmitting, isValid } = formik;
    const { hideModal } = useModal();

    function handleSubmit(formValues) {
        const newAdmin = {
            nom: formValues.nom, prenom: formValues.prenom,
            email: formValues.email, codepostal: formValues.codepostal,
            address: formValues.address, telephone: formValues.telephone,
            password: formValues.password, cal: formValues.lien,
            grade: formValues.grade
        };
        axios.post('/admin/user/add', newAdmin)
            .then(res => {
                // console.log(res)
                successToast('Utilisateur ajouté !')
                refetchDatas();
            })
            .catch(err => errorMessageManager(err))
            .finally(() => {
                hideModal()
            })
    }



    return <div className="administration-addForm">
        <FormikProvider value={formik}>
            <Form  >
                {
                    newAdminFields(gradeOptions).map((field, index) => <FormikFieldController {...field}
                        key={"admin add user field" + index} />)
                }
                <FormButton text={isSubmitting ? '' : "Ajouter"}
                    isValid={isSubmitting ? false : isValid} />
            </Form>
        </FormikProvider>

    </div>
}