import React from 'react'
import { IcBaselineDelete, IcBaselineEdit } from '../../../GlobalComponents/Icons.js'

import axios from 'axios';
import { ConfirmationMessage } from '../../../GlobalComponents/Modal.js';
import { PageTitle } from '../../../GlobalComponents/other.js';
import { useModal } from '../../../contexts/modal.js';
import { FormikProvider, useFormik, Form } from 'formik';
import { SimpleInputWithFormik } from '../../../uikits/form/simple.js';
import { FormButton } from '../../../uikits/button.js';


function BlogCategoryList({ props }) {
    const { blogCategorys, refetchBC } = props;
    const { showModal, hideModal } = useModal();

    function handleUpdate(bc) {
        showModal(<UpdateBlogCategoryModalForm
            props={{ blogCategory: bc, refreshCategorys: refetchBC }} />)
    }

    function handleConfirmDelete(bc) {
        showModal(<ConfirmationMessage
            props={{
                text: 'Voulez vous vraiment supprimer cette categorie ?',
                functionAfterConfirm: deleteBlogCategory,
                param: { target: bc, refresh: refetchBC }
            }} />)
    }

    function deleteBlogCategory(obj) {
        axios.delete('/admin/blog/category/delete/' + obj.target._id)
            .then(res => {
                obj.refresh();
            }).catch(err => {
                console.log(err);
            })
            .finally(() => {
                hideModal();
            })
    }


    return (
        <div className="bpc-categoryList">
            <PageTitle title={'Liste de vos categories'} />
            <br />
            <section className="" style={{
                justifyContent: 'center'
            }}>
                {
                    blogCategorys.length > 0 ?
                        blogCategorys.map((bc, indx) => <article key={'blog cat nb' + indx}
                            className="blogCategoryCard">
                            <b>
                                {bc.name}
                            </b>
                            <br />
                            <p>
                                <span onClick={() => handleConfirmDelete(bc)}>
                                    <IcBaselineDelete />
                                </span>
                                <span onClick={() => handleUpdate(bc)}>
                                    <IcBaselineEdit />
                                </span>
                            </p>
                        </article>)
                        : 'Aucune categorie'
                }
            </section>
        </div>
    )
}

function UpdateBlogCategoryModalForm({ props }) {
    const { hideModal } = useModal();
    const { blogCategory, refreshCategorys } = props;
    const formik = useFormik({
        initialValues: { name: blogCategory.name },
        onSubmit: handleSubmit
    })
    function handleSubmit(formValues) {
        axios.put('/admin/blog/category/update/' + blogCategory._id, { ...formValues })
            .then(res => {
                refreshCategorys()
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                hideModal()
            })
    }
    return <BlogArticleAddOrUpFormikLayout formik={formik} />
}


export function BlogArticleAddOrUpFormikLayout({ formik }) {
    return <FormikProvider value={formik}>
        <Form>
            <SimpleInputWithFormik fieldType="text" name='name' ph='Votre categorie'
                label='Nom de la categorie'
                required={true}
            />
            <FormButton text={'Enregistrer'} isValid={true} />
        </Form>
    </FormikProvider>
}

export default BlogCategoryList