import { useEffect, useState } from "react"
import axios from "axios";
import { useAuth } from "../../contexts/auth";

const useBlogArticles = () => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);
    const [loading, setL] = useState(true);

    const { user } = useAuth();
    const auth = user;
    useEffect(async () => {
        try {
            let allDatas = null;
            if (auth.grade == 'Agent') {
                allDatas = await axios.get('/admin/blog/article/agent/' + auth._id + '/list')
            } else {
                allDatas = await axios.get('/admin/blog/article/list')
            }

            setDatas(allDatas.data);
        } catch (error) {

        } finally {
            setL(false)
        }
        return setRefecth(false)
    }, [refetch, auth])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return { blogArticles: datas, baLoading: loading, refetchDatas };
}

export default useBlogArticles;