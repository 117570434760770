import React from 'react'
import AidesList from '../RoutesSubComponents/aides/aidesList'
import { withAuth } from '../hocs/withAuth'

function Aide() {
    return (
        <div className='aides'>
            <AidesList />
        </div>
    )
}

export default withAuth(Aide)
