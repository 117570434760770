import axios from "axios";
import { useModal } from "../../contexts/modal";
import { Form, FormikProvider, useFormik } from "formik";
import { upAdminFields } from "../../utils/form/fields";
import { FormButton } from "../../uikits/button";
import { UpAdminSchema } from "../../utils/form/validations";
import FormikFieldController from "../../uikits/form";
import { errorMessage, errorMessageManager, errorToast, successToast } from "../../utils/alerts";

export const UpdateAgent = ({ refetchDatas, agent }) => {
    const formik = useFormik({
        initialValues: {
            nom: agent.nom, prenom: agent.prenom, email: agent.email,
            address: agent.address, codepostal: agent.codepostal, telephone: agent.telephone,
            lien: agent.cal, isAvailable: agent.isAvailable.toString(),
            password: ""
        },
        onSubmit: handleSubmit,
        validationSchema: UpAdminSchema,
        validateOnMount: true
    });
    const { isSubmitting, isValid } = formik;
    const { hideModal } = useModal();

    function handleSubmit(formValues) {
        const agentData = {
            nom: formValues.nom, prenom: formValues.prenom,
            email: formValues.email, codepostal: formValues.codepostal,
            address: formValues.address, telephone: formValues.telephone,
            cal: formValues.lien, agentId: agent._id, isAvailable: JSON.parse(formValues.isAvailable)
        };
        if (formValues.password) {
            agentData.password = formValues.password;
        }
        axios.put('/user/update-agent', agentData)
            .then(res => {
                // console.log(res)
                successToast('Utilisateur modifié !');
                refetchDatas();
            })
            .catch(err => errorMessageManager(err))
            .finally(() => {
                hideModal()
            })
    }



    return <div className="administration-addForm">
        <FormikProvider value={formik}>
            <Form>
                {
                    upAdminFields().map((field, index) => <FormikFieldController {...field}
                        key={"admin up user field" + index}
                    // defaultValue={field.name == 'isVailable' && agent.isAvailable.toString()}
                    />)
                }
                <FormButton text={isSubmitting ? '' : "Suavegarder"}
                    isValid={isSubmitting ? false : isValid} />
            </Form>
        </FormikProvider>
    </div>
}