import { PhBellSimple, PhShieldPlus, PhChatsBold, PhFolderSimpleUserLight, PhUsersFour, PhUserSwitchDuotone, PhPresentationChart, PhUserLight, PhUserPlus, PhArrowLeftFill, PhArticle } from "../GlobalComponents/Icons"
export const navForAdmin = [
    { label: `Gestion des aides`, link: `/Aides`, icon: <PhShieldPlus /> },
    { label: `Administration`, link: `/Administration`, icon: <PhUserSwitchDuotone /> },


    { label: `Avis-trustpilot`, link: `/Avis-trustpilot`, icon: <PhChatsBold /> },
    { label: `Edition des mails`, link: `/Emails-edition`, icon: <PhChatsBold /> }
]
export const navForAll = [
    { label: `Dashboard`, link: `/Dashboard`, icon: <PhPresentationChart /> },
    { label: `Mon Profil`, link: `/Profil`, icon: <PhUserLight /> },
]
export const navForAdminAndAgent = [
    { label: `Nouveau client`, link: `/Client/creation`, icon: <PhUserPlus /> },
    { label: `Prelevement SEPA`, link: `/Prelevements`, icon: <PhFolderSimpleUserLight /> },
    { label: `Prise de contact`, link: `/Contacts`, icon: <PhFolderSimpleUserLight /> },
    { label: `Notification`, link: `/Notifications`, icon: <PhBellSimple /> },
]

export const navForAdminAgentAndCommercial = [
    { label: `Clients`, link: `/Client/liste`, icon: <PhUsersFour /> },
    { label: `Simulations`, link: `/Simulations`, icon: <PhFolderSimpleUserLight /> },
]

export const navForBlogerUser = [
    { label: `Categories du Blog`, link: `/Blog/category`, icon: <PhArticle /> },
    { label: `Ajoutez un Article`, link: `/Blog/article/add`, icon: <PhArticle /> },
    { label: `Liste des Articles`, link: `/Blog/article/list`, icon: <PhArticle /> },
]

export const adminAndAgent = ['Admin', 'Agent'];
export const adminAgentAndCommercial = ['Admin', 'Agent', 'Commercial'];
