import { useEffect, useState } from "react"
import axios from "axios";


const useTransaction = (clientId, initialTransaction = []) => {
    const [transactions, setTransactions] = useState(initialTransaction);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const allTransactions = await axios.get('/admin/Transactions/' + clientId)
            setTransactions(allTransactions.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchTransactions = () => {
        setRefecth(true)
    };

    return { transactions, refetchTransactions };
}

export default useTransaction;