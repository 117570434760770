import { useField } from "formik";
// import { IonEdit, IonIosTrash } from "../../GlobalComponents/icons";
export function DocFileInputWithFormik(props) {
    const { name, label, accept, align } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    function getStyleByAlignKey() {
        if (!align) {
            return { marginRight: 'auto' }
        } else {
            if (align == 'left') {
                return { marginRight: 'auto' }
            }
            return { marginLeft: 'auto' }
        }
    }

    function handleNewFile(e) {
        setValue(e.target.files[0])
    }

    function handleUpdateFile(e, i) {
        setValue(e.target.files[0])
    }

    function handleDelete(i) {
        const copy = [...value];
        copy.splice(i, 1)
        setValue(copy)
    }
    return <div className="formSegment formSegmentFiles"
        style={getStyleByAlignKey()}
    >
        <label>{label}</label>
        <div className="fsf-newFile flex f-column">
            <input type="file" name={name}
                id={"fileInp-" + name}
                onChange={handleNewFile}
                accept={accept ? accept : ''}
            />
            <label htmlFor={"fileInp-" + name}>
                {
                    value ? <span>{value.name}</span> : <span>+</span>
                }
            </label>
        </div>

    </div>
}

export function DocFilesInputWithFormik(props) {
    const { name, label, accept, align } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    function getStyleByAlignKey() {
        if (!align) {
            return { marginRight: 'auto' }
        } else {
            if (align == 'left') {
                return { marginRight: 'auto' }
            }
            return { marginLeft: 'auto' }
        }
    }

    function handleNewFile(e) {
        setValue([...value, e.target.files[0]])
    }

    function handleUpdateFile(e, i) {
        const copy = [...value];
        copy[i] = e.target.files[0];
        setValue(copy)
    }

    function handleDelete(i) {
        const copy = [...value];
        copy.splice(i, 1)
        setValue(copy)
    }
    return <div className="formSegment formSegmentFiles"
        style={getStyleByAlignKey()}
    >
        <label>{label}</label>
        <div className="fsf-files flex f-wrap">
            {
                value.length > 0 && value.map((item, i) => <article
                    key={'inpu file nb' + i}
                    className="flex">
                    <input type="file" name={name}
                        id={"fileInp-" + name + 'nb' + i}
                        onChange={(e) => handleUpdateFile(e, i)}
                        accept={accept ? accept : ''}
                    />
                    <p>
                        {value[i].name}
                    </p>
                    <div className="flex">
                        <span>
                            <label article htmlFor={"fileInp-" + name + 'nb' + i}>
                                {/* <IonEdit /> */}Edit
                            </label>

                        </span>
                        <span onClick={() => handleDelete(i)}>
                            Supprime
                        </span>
                    </div>
                </article>)
            }
        </div>
        <div className="fsf-newFile flex f-column">
            <input type="file" name={name}
                id={"fileInp-" + name}
                onChange={handleNewFile}
                accept={accept ? accept : ''}
            />
            <label htmlFor={"fileInp-" + name}>
                <span>
                    Nouveau Fichier +
                </span>
            </label>
        </div>

    </div>
}
