import axios from "axios";
import { ClientInfosTypeFields } from "../helpers/infosFormUI";
import { useModal } from "../../../contexts/modal";
import { FormButton } from "../../../uikits/button";
import { FormikProvider, Form, useFormik } from "formik";
import { errorToast, successToast } from "../../../utils/alerts";
import { useState } from "react";

const AddClientDataModal = ({ props }) => {
  const { navValue, currentClient, agentConnectedInfo } = props;
  const { hideModal } = useModal();
  const [sendTrustPilotEmail, setSTPE] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      sujet: "",
      message: "",
      aide: "",
      montant: "",
      phase: "",
      note: "",
      date: "",
      time: "",
      files: "",
      structure: "",
      numero_secu: "",
      password: "",
    },
    onSubmit: handleSubmit,
  });
  const { isValid, isSubmitting } = formik;

  function handleSubmit(formValues) {
    let reqUrl = "";
    let reqBody = "";
    if (navValue == "emails") {
      reqUrl = "/admin/email";
      reqBody = new FormData();

      let newAlerte = {
        titre: formValues.title,
        clientId: currentClient._id,
        email: currentClient.email,
        sujet: formValues.sujet,
        message: formValues.message,
        userId: agentConnectedInfo._id,
        nom: agentConnectedInfo.nom + " " + agentConnectedInfo.prenom,
        sender: agentConnectedInfo.email,
        // "files": formValues.files,
      };
      reqBody.append("info", JSON.stringify(newAlerte));
      for (let index = 0; index < formValues.files.length; index++) {
        reqBody.append("files", formValues.files[index]);
      }
      reqBody.append("avatar", formValues.files);
    } else if (props.navValue == "notes") {
      reqUrl = "/notes/add";
      reqBody = {
        clientId: currentClient._id,
        data: formValues.note,
        date: new Date(),
        time: "00:00",
        title: formValues.title,
      };
    } else if (props.navValue == "transactions") {
      reqUrl = "/admin/transaction/add";
      reqBody = {
        clientId: currentClient._id,
        aide: formValues.aide,
        montant: formValues.montant,
        phase: formValues.phase,
        titre: formValues.title,
      };
      if (sendTrustPilotEmail) {
        reqBody.sendTrustPilotEmail = true;
      }
    } else if (props.navValue == "passwords") {
      reqUrl = "/clientPwd/add";
      reqBody = {
        clientId: currentClient._id,
        numero_secu: formValues.numero_secu,
        structure: formValues.structure,
        password: formValues.password,
      };
    }
    axios
      .post(reqUrl, reqBody)
      .then((res) => {
        hideModal();
        successToast("Operation effectuée !");
      })
      .catch((err) => {
        console.log(err);
        errorToast("Erreur survenue");
      })
      .finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  return (
    <div className="clientInformationAddModal">
      <FormikProvider value={formik}>
        <Form>
          <ClientInfosTypeFields
            props={{
              infosType: navValue,
            }}
          />
          {props.navValue == "transactions" && (
            <div>
              <section className="additionalFormOptions">
                <input
                  type="checkbox"
                  name="sendTrustPilotEmail"
                  id="sendTrustPilotEmailId"
                  onChange={(e) => setSTPE((prev) => !prev)}
                />
                <label htmlFor="sendTrustPilotEmailId">
                  Envoyer l'email d'avis Trustpilot
                </label>
              </section>
            </div>
          )}
          <FormButton
            text={isSubmitting ? "" : "Ajouter"}
            isValid={isSubmitting ? false : true}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default AddClientDataModal;
