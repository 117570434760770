import { PageTitle } from "../GlobalComponents/other";
import useAdminAndAgents from "../Hooks/useAdminAndAgents";
import { AdministratorList } from "../RoutesSubComponents/administration/list.js";
import { withAuth } from "../hocs/withAuth";
import { SectionLoader } from "../uikits/others";
export const Administration = () => {

    const { adminAndAgents, adminAndAgentsLoading, refetchDatas } = useAdminAndAgents();


    if (adminAndAgentsLoading)
        return <SectionLoader />
    return <div className="administration">
        {
            adminAndAgents.length > 0 ? <>
                <PageTitle title={'Administration'} />
                <AdministratorList props={{ adminAndAgents, refetchDatas }} />
            </> : 'Aucun agent'
        }
    </div>
}
export default withAuth(Administration);