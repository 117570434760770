
function getValueOrReturnZero(value) {
    if (value) {
        return value
    }
    return '0';
}

export const ClientRessources = ({ ressources }) => {

    return <div className="client-ressources">
        {
            ressources && ressources != null && ressources.salaires_enfants ? <>
                <section className="cr-childs">
                    <h3>Les enfants et leurs salaires respectifs</h3>
                    {
                        ressources.salaires_enfants.length > 0 ?
                            ressources.salaires_enfants.map((enfant, index) => <div key={"c-note nb" + index}>
                                <p>
                                    <b>Nom et prenom Enfant :</b> {enfant.nom}
                                </p>
                                <p>
                                    <b>Date :</b> ...
                                </p>
                                <p>
                                    <b>Heure :</b> ...
                                </p>

                            </div>)
                            : <p className="notFoundTxt">Ce client n'a pas d'enfant</p>
                    }

                </section>
                <section className="cr-salaire-trimestrielles">
                    <h3>Salaire sur le dernier trimestre</h3>
                    <p>
                        <b>Trimestre 1 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles.trimestre_1)} €
                    </p>
                    <p>
                        <b>Trimestre 2 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles.trimestre_2)} €
                    </p>
                    <p>
                        <b>Trimestre 3 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles.trimestre_3)} €
                    </p>
                </section>
                <section className="cr-salaire-trimestrielles">
                    <h3>Salaire du conjoint sur le dernier trimestre</h3>
                    <p>
                        <b>Trimestre 1 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles_conjoint.trimestre_1)} €
                    </p>
                    <p>
                        <b>Trimestre 2 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles_conjoint.trimestre_2)} €
                    </p>
                    <p>
                        <b>Trimestre 3 :</b>
                        {getValueOrReturnZero(ressources.salaires_trimestrielles_conjoint.trimestre_3)} €
                    </p>
                </section>
            </> : <p>Ce client n'a pas de ressources</p>
        }
    </div>
}