import useAllReviewsLikeTrustpilot from '../../Hooks/trustpilot'
import axios from 'axios';
import { PhNotePencil, PhTrash } from '../../GlobalComponents/Icons';
import { ConfirmationMessage } from '../../GlobalComponents/Modal';
import { useModal } from '../../contexts/modal';
import { Form, FormikProvider, useFormik } from 'formik';
import { newTrustPilotReview } from '../../utils/form/fields';
import FormikFieldController from '../../uikits/form';
import { FormButton } from '../../uikits/button';
import { NewReviewSchema } from '../../utils/form/validations';
function TrustpilotReviewManager() {
    const { reviews, refreshReviews } = useAllReviewsLikeTrustpilot();
    const { showModal, hideModal } = useModal();
    function showNewReviewModal() {
        showModal(<AddOrUpdateReviewForm customprops={{ refreshReviews }} />)
    }
    function showUpdateReviewModal(currentReview) {
        showModal(<AddOrUpdateReviewForm customprops={{ refreshReviews, update: true, currentReview }} />)
    }

    function showDeleteReviewModal(currentReview) {
        function deleteReview() {
            axios.delete('/avis/like-trustpilot/' + currentReview._id)
                .then(res => refreshReviews())
                .catch(err => alert('Erreur survenue'))
                .finally(() => {
                    hideModal()
                })
        }
        showModal(<ConfirmationMessage
            props={{
                text: "Voulez vous vraiment supprimer cet avis ?",
                functionAfterConfirm: deleteReview
            }}
        />)
    }
    return (
        <div className='av-reviewsManager'>
            <button onClick={showNewReviewModal}>Ajouter un nouvel avis</button>
            <section>
                <h2>Liste des avis</h2>
                {
                    reviews.length == 0 ? <>Aucun avis repertorier</> :
                        <>
                            {
                                reviews.map((item, i) => <article key={'trustpilot review nb' + i}
                                    className='reviewBox'>
                                    <h4>{item.userName}</h4>
                                    <span>Pays: {item.userCountry} ,{item.stars + ' etoiles'} </span>
                                    <p>
                                        {item.message}
                                    </p>
                                    <b>
                                        {item.messageDate}
                                    </b>
                                    <section className='reviewBoxActions'>
                                        <PhTrash className="deleteBtn" onClick={() => showDeleteReviewModal(item)} />
                                        <PhNotePencil className="updateBtn" onClick={() => showUpdateReviewModal(item)} />
                                    </section>
                                </article>)
                            }
                        </>
                }
            </section>
        </div>
    )
}

function AddOrUpdateReviewForm(props) {
    const { showModal, hideModal } = useModal();
    const { refreshReviews, update, currentReview } = props.customprops;
    const formik = useFormik({
        initialValues: {
            userName: update ? currentReview.userName : '',
            userCountry: 'FR',
            message: update ? currentReview.message : '',
            messageDate: update ? currentReview.messageDate : '',
            stars: update ? currentReview.stars : 1
        },
        onSubmit: handleSubmit,
        validateOnMount: true,
        validationSchema: NewReviewSchema
    });



    function handleSubmit(formValues) {
        const reqUrl = update ? '/avis/like-trustpilot/update/' + currentReview._id : '/avis/like-trustpilot/add';
        axios.post(reqUrl, formValues)
            .then(res => refreshReviews())
            .catch(err => alert('Erreur survenue'))
            .finally(() => { hideModal(); })
    }
    return <div>
        <h2>Nouvel avis trustpilot</h2>
        <FormikProvider value={formik}>
            <Form>
                {
                    newTrustPilotReview.map((item, i) => <FormikFieldController
                        {...item}
                        key={'field nb' + i}
                    />)
                }
                <FormButton text={'Ajouter'} isValid={true} />
            </Form>
        </FormikProvider>
    </div>
}

export default TrustpilotReviewManager