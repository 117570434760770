import { ConnectedNavbar } from "../GlobalComponents/Navigation/Navbar";
import { useAuth } from "../contexts/auth";
import { Navigate } from "react-router-dom";
import { FullScreenLoader } from "../uikits/others";

export const withAuth = (WrappedComponent, specificRoles = []) => {
    return (props) => {
        const { user, userLoading } = useAuth();

        if (userLoading)
            return <FullScreenLoader />;

        if (!user) {
            return <Navigate to='/' />;
        }

        if (specificRoles && specificRoles.length > 0) {
            if (!specificRoles.includes(user.grade))
                return <Navigate to='/' />;
        }
        return <>
            <ConnectedNavbar />
            <div className="authedContainer">
                <WrappedComponent {...props} />
            </div>
        </>
    };
};
