import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { successToast } from '../utils/alerts';
import useAppEmails from '../Hooks/useAppEmails';
import { useModal } from '../contexts/modal';
import { withAuth } from '../hocs/withAuth';

export function sortJsonFileData(dataToSort) {
    return dataToSort.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0;
    });
}

function MailEdit() {
    const { emails, refetchAppEmails, appEmailError } = useAppEmails()

    const { showModal } = useModal();

    if (appEmailError) {
        return <p>Une erreur est survenue pendant la recuperation des emails</p>
    }
    return (
        <div className='emails-edition'>
            <h1>Editer vos emails</h1>
            <p>
                Vous avez la possibilité ici d'editer les differents mails qui sont envoyer via la plateforme
            </p>

            <section className='flex f-wrap ee-emailsList'>
                {
                    sortJsonFileData(emails).map((email, i) => <article key={'email to edit nb' + i}>
                        <h3>{email.title} </h3>
                        <div className='flex' style={{ gap: '0 10px' }}>
                            <button
                                style={{
                                    padding: '10px 15px'
                                }}
                                onClick={() => showModal(<EditMailTitleForm
                                    currentTitle={email.title}
                                    emailId={email._id}
                                    refetchAppEmails={refetchAppEmails} />
                                )}
                            >Editer le titre</button>
                            <button
                                style={{
                                    padding: '10px 15px'
                                }}
                                onClick={() => showModal(<EditMailBodyForm
                                    currentBody={email.body}
                                    emailId={email._id}
                                    refetchAppEmails={refetchAppEmails} />
                                )}
                            >Editer le contenu</button>
                        </div>
                    </article>)
                }
            </section>
        </div>
    )
}

function EditMailTitleForm({ currentTitle, emailId, refetchAppEmails }) {
    const [title, setTitle] = useState(currentTitle);
    const { hideModal } = useModal();

    function handleSubmit(e) {
        e.preventDefault();
        axios.put('/admin/emails-edit/edit', {
            emailId,
            fileNewTitle: title
        })
            .then(res => successToast('Mail modifier'))
            .catch(res => null)
            .finally(res => {
                hideModal();
                refetchAppEmails()
            });
    }
    return <form onSubmit={handleSubmit}>
        <div className="formSegment">
            <section><input type="text"
                placeholder='Le titre du mail ici'
                value={title}
                onChange={(e) => setTitle(e.target.value)} />
            </section>
        </div>
        <button style={{
            padding: '10px 15px',
            backgroundColor: 'var(--bgColor1)',
            color: 'white'
        }}>Enregistrer</button>
    </form>
}

function EditMailBodyForm({ currentBody, emailId, refetchAppEmails }) {
    const [emailBody, setEmailBody] = useState(currentBody);
    const { hideModal } = useModal();

    function handleSubmit(e) {
        e.preventDefault();
        axios.put('/admin/emails-edit/edit', {
            emailId,
            fileNewBody: emailBody
        })
            .then(res => successToast('Mail modifier'))
            .catch(res => null)
            .finally(res => {
                hideModal();
                refetchAppEmails()
            });
    }
    return <form onSubmit={handleSubmit}>
        <ReactQuill theme="snow" value={emailBody}
            onChange={setEmailBody}
            modules={
                {
                    toolbar: [
                        [{ 'header': [1, 2, 3, 4, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            } />
        <button style={{
            padding: '10px 15px',
            backgroundColor: 'var(--bgColor1)',
            color: 'white'
        }}>Enregistrer</button>
    </form>
}

export default withAuth(MailEdit)
