import { useNavigate } from "react-router";
import { useAuth } from "../contexts/auth";
import { Navigate } from "react-router-dom";
import { FullScreenLoader } from "../uikits/others";

export const withoutAuth = (WrappedComponent) => {
    return (props) => {
        const { user, userLoading } = useAuth();
        const navigate = useNavigate();

        if (userLoading)
            return <FullScreenLoader />;
        if (user) {
            return <Navigate to='/Dashboard' />;
        }

        return <WrappedComponent {...props} />
    };
};