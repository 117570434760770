import { PageTitle } from "../GlobalComponents/other";
import { DashboardLastClientList, DashboardStats } from "../RoutesSubComponents/dashboard";
import { useAuth } from "../contexts/auth";
import { withAuth } from "../hocs/withAuth";
export const Dashboard = () => {
    const { user } = useAuth();
    const authed = user;

    return <div className="dashboard">
        <PageTitle title={'Tableau de bord'} />
        <DashboardStats authed={authed} />
        {
            authed.grade != 'Bloger' ?
                <DashboardLastClientList authed={authed} /> : ''
        }


    </div>
}
export default withAuth(Dashboard);