import React from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ConfirmationMessage } from '../../../GlobalComponents/Modal';
import useBlogArticles from '../../../Hooks/blog/useArticles';
import { PageTitle } from '../../../GlobalComponents/other';
import { useAuth } from '../../../contexts/auth';
import { useModal } from '../../../contexts/modal';
import { SectionLoader } from '../../../uikits/others';

function BlogArticlesList() {
    const { user } = useAuth();
    const auth = user;
    const { blogArticles, baLoading, refetchDatas } = useBlogArticles();
    const { showModal, hideModal } = useModal();

    function confirmArticleDelete(target) {
        showModal(
            <ConfirmationMessage props={{
                text: 'Voulez vous vraiment supprimer cet article ?',
                functionAfterConfirm: deleteArticle, param: { ...target }
            }} />
        )
    }

    function deleteArticle(target) {
        axios.delete('/admin/blog/article/delete/' + target._id)
            .then(res => {
                refetchDatas()
                hideModal()
            }).catch(err => {
                alert('Erreur survenue')
            })
    }

    if (baLoading)
        return <SectionLoader />
    return (
        <div>
            <PageTitle title={'Liste des articles'} />
            <table style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <thead>
                    <tr>
                        <th>Titre</th>
                        <th>Categorie</th>
                        <th>Vue</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        blogArticles.length > 0 ?
                            blogArticles.map((art, index) => <tr key={"client sim nb" + index}>
                                <td>{art.title}</td>
                                <td>{art.categoryId.name}</td>
                                <td>{art.views.length}</td>
                                <td>
                                    <Link to={'/Blog/article/view/' + art._id}>Voir</Link>
                                    <br />
                                    {
                                        auth && auth.grade != 'Admin' ? <>
                                            <Link to={'/Blog/article/update/' + art._id}>Editer</Link>
                                            <br />
                                            <button
                                                onClick={() => confirmArticleDelete(art)}>Supprimer</button>
                                        </> : ''
                                    }

                                </td>
                            </tr>) : <tr>
                                <td colSpan={4}>Aucun article</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default BlogArticlesList