import { useEffect, useState } from "react"
import axios from "axios";

const useBlogComments = (articleId) => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);

    const apiUrl = process.env.NODE_ENV == 'production' ? 'https://dashboard.france-assist.fr'
        : 'http://localhost:5110';
    useEffect(async () => {
        try {
            const allDatas = await axios.get(apiUrl + '/admin/blog/comment/list/' + articleId)
            setDatas(allDatas.data);
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return { blogComments: datas, refetchDatas };
}

export default useBlogComments;