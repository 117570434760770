import { PageTitle } from '../GlobalComponents/other'
import TrustPilotReviewsCountManager from '../RoutesSubComponents/trustpilot/countManager';
import TrustpilotReviewManager from '../RoutesSubComponents/trustpilot/reviewManager';
import { withAuth } from '../hocs/withAuth';

function TrustpilotReview() {

    return (
        <div className='avisTrustpilot'>
            <PageTitle title={`Gestionnaire d'avis Trustpilot`} />
            <TrustPilotReviewsCountManager />
            <TrustpilotReviewManager />
        </div>
    )
}

export default withAuth(TrustpilotReview)