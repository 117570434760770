import { toast } from "react-toastify";

export function successToast(message, options = false) {
    return toast.success(message, options && { ...options })
}

export function errorToast(message, options = false) {
    return toast.error(message, options && { ...options })
}


export const errorMessages = {
    emailAlreadyExist: 'Email deja utiliser',
    errorOccur: 'Une erreur est survenue',
}

export function errorMessageManager(err) {
    return errorToast(errorMessages[err.response.data.error])
}
