import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'
import { PageLoader, PageTitle } from '../../../GlobalComponents/other';
import useBlogComments from '../../../Hooks/blog/useBlogComments';
import { PhUserLight } from '../../../GlobalComponents/Icons';
import { useAuth } from '../../../contexts/auth';
import { FormikProvider, useFormik, Form } from 'formik';
import { TextAreaWithFormik } from '../../../uikits/form/simple';
import { FormButton } from '../../../uikits/button';
function ViewBlogArticle({ actionId }) {
    const [article, setArticle] = useState('');
    const { user } = useAuth();
    const auth = user;
    const authedAutor = auth;
    useEffect(() => {
        axios.get('/admin/blog/article/' + actionId)
            .then(res => {
                setArticle(res.data)
            }).catch(err => {
                alert('Errur survenue')
            })
    }, [])


    function createMarkup(rawHTML) {
        return { __html: rawHTML };
    } return (
        <div className='bp-view'>
            {
                article && article._id ? <>
                    <PageTitle title={'Details article'} />
                    <section className='bpv-titles'>
                        <div>
                            <h2>Titre de l'article</h2>
                            <h4>{article.title}</h4>
                        </div>
                        <div>
                            <h2>Description de l'article</h2>
                            <h4>
                                {article.description}
                            </h4>
                        </div>
                        <div>
                            <h2>Categorie de l'article</h2>
                            <h4>
                                Categorie
                            </h4>
                        </div>
                        <div>
                            <h2>Banniere de l'article</h2>
                            <img src={article.banner} alt="article banner" />

                        </div>

                    </section>
                    <div className='bpv-content' dangerouslySetInnerHTML={createMarkup(article.content)} />
                    <ArticleCommentsSection articleId={actionId} authedAutor={authedAutor} />

                </> : <PageLoader />
            }
        </div>
    )
}



function ArticleCommentsSection({ authedAutor, articleId }) {
    const { blogComments, refetchDatas } = useBlogComments(articleId);
    const [formValues, setFormValues] = useState({
        autorName: authedAutor.prenom + ' ' + authedAutor.nom,
        autorEmail: authedAutor.email, message: ''
    });
    const [commentResponsesVisibleId, setCRVI] = useState(-1);
    const [commentFormVisibleId, setCFVI] = useState(-1);
    const formik = useFormik({
        initialValues: {
            autorName: authedAutor.prenom + ' ' + authedAutor.nom,
            autorEmail: authedAutor.email, message: ''
        }
    })


    function toggleAllCommentResponse(i) {
        setCRVI((prev) => {
            return prev == i ? -1 : i;
        })
    }

    function handleCommentAdd(e) {
        e.preventDefault()
        axios.post('/admin/blog/comment/add', { ...formValues, articleId })
            .then(res => alert('Commentaire ajouté'))
            .catch(err => console.log('blog err'))
            .finally(() => {
                refetchDatas()
                setFormValues((prev) => {
                    return { ...prev, message: '' }
                })
            })
    }

    function handleCommentResponseAdd(e, commentId) {
        e.preventDefault()
        axios.post('/admin/blog/comment/' + commentId + '/response/add', formValues)
            .then(res => {
                alert('Reponse ajouté');
            })
            .catch(err => console.log('blog err'))
            .finally(() => {
                refetchDatas()
                setCFVI(-1)
                setFormValues((prev) => {
                    return { ...prev, message: '' }
                })
            })
    }

    function toggleCurrentForm(i) {
        setCFVI((prev) => {
            return prev == i ? -1 : i;
        })
    }
    return <div className="articleCommentSection">
        <h2>Espace commentaire</h2>
        <br />
        <div className="accCommentList">
            {
                blogComments.length ? <>
                    {
                        blogComments.map((bc, i) => <article className='commentSection' key={'blog com' + i}>
                            <span>
                                <PhUserLight />
                            </span>
                            <div>
                                <b>{bc.autorName}</b>
                                <p>
                                    {
                                        bc.message
                                    }
                                    <br />
                                </p>
                                <br />
                                <section className='cs-actions'>
                                    <button onClick={() => {
                                        toggleCurrentForm(i + 1)
                                    }}>Repondre</button>
                                    <button onClick={() => toggleAllCommentResponse(i + 1)}>
                                        Voir les reponses</button>
                                </section>
                                <section className={'accCommentResponsesList '
                                    + (commentResponsesVisibleId == (i + 1) ? 'accCommentResponsesListVisible' : '')}>
                                    {
                                        bc.responses.length > 0 ? <>
                                            {
                                                bc.responses.map((bcr, j) => <ResponseCommentLayout
                                                    response={bcr}
                                                    key={'commen' + i + 'response ' + j}
                                                />)
                                            }
                                        </>
                                            : 'Aucune reponse a commentaire'
                                    }
                                </section>
                                <FormikProvider value={formik}>
                                    <Form className={'commentForm ' +
                                        (commentFormVisibleId == (i + 1) ? 'commentFormVisible' : '')}>
                                        <TextAreaWithFormik name='message' ph='Message' label='Votre message' />
                                        <FormButton text="Envoyer" isValid={true} />
                                    </Form>
                                </FormikProvider>
                            </div>
                        </article>)
                    }
                </> : 'Aucun commentaires'
            }
        </div>
        <br /><br />

        <h4>Mon commentaire</h4>
        <FormikProvider value={formik}>
            <Form className={'commentForm commentFormVisible'}>
                <TextAreaWithFormik name='message' ph='Message' label='Votre message' />
                <FormButton text="Envoyer" isValid={true} />
            </Form>
        </FormikProvider>
    </div>
}



// handleCommentResponseAdd(e, bc._id)
{/* <form onSubmit={handleCommentAdd}
            >
            <TextArea props={{
                name: 'message', ph: 'Message', label: 'Votre message',
                formValues, setFormValues, normalizer: 'onlyLetterAndNumberWithSpace'
            }} />
        </form> */}


export function ResponseCommentLayout({ response }) {
    return <article className='commentSection' id={'commentResponseId-' + response._id}>
        <span>
            <PhUserLight />
        </span>
        <div>
            <b>{response.autorName}</b>
            <br />
            <p>
                {
                    response.message
                }
            </p>
        </div>
    </article>
}

export default ViewBlogArticle