import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setCurrentClientToStore } from "../../Store/actions/clientActions"
import moment from "moment"
import { PhUserCircleFill } from "../../GlobalComponents/Icons"
import { PageTitle, SearchDataAndAddNewDataSection } from "../../GlobalComponents/other"
import { getAgent } from "../../utils/forClient"
import xlsx from "json-as-xlsx"
import { useAuth } from "../../contexts/auth"
import { useModal } from "../../contexts/modal"
import { SectionLoader } from "../../uikits/others"



const ClientList = () => {
    const { user } = useAuth();
    const authed = user;
    const [clients, setClients] = useState([]);
    const [agents, setAgents] = useState([]);
    const [cLoading, setCLoading] = useState(true);


    const [finalClients, setFinalClients] = useState([]);
    const dispatch = useDispatch();
    const { showModal } = useModal();

    useEffect(async () => {
        let clientReqUrl = '';
        if (authed) {
            clientReqUrl = authed.grade === 'Agent' ? 'clients' : 'all';

            try {
                const allClients = await axios.get('/admin/' + clientReqUrl);
                const filterClients = allClients.data.filter(cl => !["61557fb10801460fb4c166e8",
                    "61575c403210f60b1c59a4b1", "61575cc33210f60b1c59a4b5"].includes(cl._id))
                const allAgents = allClients.data.filter(cl => ["61575c403210f60b1c59a4b1", "61575cc33210f60b1c59a4b5"].includes(cl._id))
                setClients(filterClients)
                setFinalClients(filterClients)
                setAgents(allAgents)
            } catch (error) {
                console.log(error)
            } finally {
                setCLoading(false)
            }
        }

    }, [authed]);

    function searchClients(event) {
        let value = event.target.value.toLowerCase();
        const clientsCopy = clients;

        const searchRes = clientsCopy.filter(cl => cl.nom.toLowerCase().includes(value)
            || cl.prenom.toLowerCase().includes(value) || cl.email.toLowerCase().includes(value) || cl.telephone.includes(value))

        setFinalClients(searchRes)

    }

    function filterClientByAgent(e) {
        const { value } = e.target;
        if (value) {
            let copyClients = [...clients]
            let filter = copyClients.filter(item => item.agent == value)
            setFinalClients(filter);
        } else {
            setFinalClients(clients)
        }
    }

    const clientFields = [
        { label: "Nom", value: (row) => row.nom },
        { label: "Prenom", value: (row) => row.prenom },
        { label: "Mail", value: (row) => row.email },
        { label: "TelePhone", value: (row) => row.telephone },
        { label: "Date de creation", value: (row) => moment(row.created_at).format("DD MMM Y H:mm") },
    ]

    let xlsData = [
        {
            sheet: "Clients par conseiller(e)",
            columns: [...clientFields],
            content: finalClients,
        }
    ];
    let xlsxSettings = {
        fileName: "Clients par conseiller(e)",
        extraLength: 3,
        writeMode: 'writeFile',
        writeOptions: {},
        RTL: false,
    }

    if (cLoading) {
        return <SectionLoader />
    }
    return <div className="clients-list">
        {
            clients.length > 0 ? <>
                <PageTitle title={'Liste des clients'} />
                {
                    authed.grade == 'Admin' && <div className="cl-filterAndExport">
                        <div>
                            <p>Filtrer par conseiller(ere)</p>
                            <select name="" onChange={filterClientByAgent}>
                                <option value={''}>Tout</option>
                                {
                                    agents.map((item, i) => <option
                                        key={'golem nb' + i}
                                        value={item._id}>{item.nom + ' ' + item.prenom} </option>)
                                }
                            </select>
                        </div>
                        <button onClick={() => xlsx(xlsData, xlsxSettings)}>Exporter</button>
                    </div>
                }
                <SearchDataAndAddNewDataSection onSearch={searchClients}
                    newDataAction={{ btnMessage: 'Nouveau Client(e)', addLink: '/Client/creation' }} />
                <p className="clientsCount">
                    <b>{finalClients.length} </b> Client(e)s
                </p>
                <br /><br />
                <section className="d-c-list">
                    {
                        finalClients.length ? finalClients.map((cl, index) => <div key={"client nb*" + index}>
                            <PhUserCircleFill style={{ fontSize: '50px', margin: 'auto', color: 'var(--bgColor1)' }} />
                            <p>{cl.nom + " " + cl.prenom}</p>
                            <p>{cl.email}</p>
                            <br />
                            <article>
                                <button onClick={() => {
                                    dispatch(setCurrentClientToStore(cl))
                                    showModal(<PreviewClient client={cl}
                                        agents={agents} authed={authed} />)
                                }}>Apercu</button>
                            </article>
                            <br />
                        </div>)
                            : <SectionLoader />
                    }
                </section >
            </> : 'Aucun client trouver'
        }
    </div >
}


export const PreviewClient = ({ client, agents, authed }) => {

    return <div className="cl-clientPreview">
        {/* <PhUserCircleFill /> */}
        <p><b>Nom et Prenom: </b>{client.nom + ' ' + client.prenom}</p>
        <p><b>Mail:</b> {client.email}</p>
        <p><b>Code postal:</b> {client.codepostal}</p>
        <p><b>Contact:</b> {client.telephone} </p>
        <p><b>Compte crée le :</b> {moment(client.created_at).format('DD-MM-YYYY')} </p>
        {
            authed.grade == 'Admin' ?
                agents.length > 0 && <p><b>Agent du client :</b> {
                    getAgent(agents, client) ? getAgent(agents, client).prenom + ' ' + getAgent(agents, client).nom : 'Non definis'
                } </p> : ''
        }


        <section>
            <Link to={"/Client/" + client._id + '/ressources'}>Ressources</Link>
            <Link to={"/Client/" + client._id + '/notes'}> Notes</Link>
            <Link to={"/Client/" + client._id + '/emails'}> Emails</Link>
            <Link to={"/Client/" + client._id + '/transactions'}> Transactions</Link>
            <Link to={"/Client/" + client._id + '/documents'}> Documents</Link>
            <Link to={"/Client/" + client._id + '/simulations'}> Simulations</Link>
            <Link to={"/Client/" + client._id + '/passwords'}> Mots de passe</Link>
        </section>


    </div>
}

export default ClientList;