import { useEffect, useState } from "react"
import axios from "axios";

const useBlogCategory = () => {
    const [datas, setDatas] = useState([]);
    const [refetch, setRefecth] = useState(false);
    const [loading, setL] = useState(true);
    useEffect(async () => {
        try {
            const allDatas = await axios.get('/admin/blog/category/list')
            setDatas(allDatas.data)
        } catch (error) {

        } finally {
            setL(false);
        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return { blogCategorys: datas, bcLoading: loading, refetchDatas };
}

export default useBlogCategory;