import React from 'react'
import axios from 'axios';
import { useModal } from '../../../contexts/modal';
import { BlogArticleAddOrUpFormikLayout } from './list';
import { useFormik } from 'formik';

function AddBlogCategory({ props }) {
    const { showModal } = useModal();
    return (
        <div style={{ textAlign: 'center' }}>
            <button onClick={() => showModal(<AddBlogCategoryForm
                refetchBC={props.refetchBC} />)}>
                Ajoutez une nouvelle categorie</button>
        </div>
    )
}

function AddBlogCategoryForm({ refetchBC }) {
    const formik = useFormik({
        initialValues: { name: '' },
        onSubmit: handleSubmit
    })
    const { hideModal } = useModal();

    function handleSubmit(formValues) {
        axios.post('/admin/blog/category/add', { ...formValues })
            .then(res => {
                console.log(res.data);
                refetchBC();
            }).catch(err => {
                console.log(err);
            })
            .finally(() => hideModal())
    }
    return <BlogArticleAddOrUpFormikLayout formik={formik} />
}

export default AddBlogCategory