import { useEffect, useState } from "react"
import axios from "axios";

export const useAides = (initialAides = []) => {
    const [aides, setAides] = useState(initialAides);
    const [loading, setLoading] = useState(true)
    const [refetch, setRefecth] = useState(false)

    useEffect(async () => {
        try {
            const allAide = await axios.get("/admin/aide/list")
            const sorter = allAide.data
            sorter.sort(function (a, b) {
                return a.title.localeCompare(b.title, 'fr',);
            });
            setAides(sorter)
        } catch (error) {

        } finally {
            setLoading(false)
        }
        return setRefecth(false)
    }, [refetch])


    const refetchAides = () => {
        setRefecth(true)
    };

    return { aides, aLoading: loading, refetchAides };
}