import { useEffect, useState } from "react"
import axios from "axios";

const useNotifications = (page) => {
    const [datas, setDatas] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refetch, setRefecth] = useState(false);

    useEffect(async () => {
        try {
            const allDatas = await axios.get('/admin/notifications?currentPage=' + page)
            setDatas(allDatas.data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setLoading(true)
        setRefecth(true)
    };

    return { notifications: datas, nLoading: loading, refetchNotifications: refetchDatas };
}

export default useNotifications;