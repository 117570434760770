import React from 'react'
import GlobalTable from '../RoutesSubComponents/table'
import axios from 'axios';
import { PageTitle } from '../GlobalComponents/other';
import useClientsContacts from '../Hooks/useClientsContact';
import { withAuth } from '../hocs/withAuth';
import { SectionLoader } from '../uikits/others';

function Contacts() {

    const { clientContacts, ccLoading } = useClientsContacts();


    function deleteFunction(contactId) {
        axios.delete("/form/contact/delete/" + contactId)
            .then(res => window.location.reload())
            .catch(err => console.log(err))
    }

    if (ccLoading)
        return <SectionLoader />;
    return (
        <div className='tablesInfos'>
            <PageTitle title={'Imformations des Contact Clients'} />
            <section className='scrollableTableContainer'>
                <GlobalTable props={{
                    data: clientContacts,
                    typeInfo: "contact", deleteFunction
                }} />
            </section>
        </div>
    )
}

export default withAuth(Contacts)
