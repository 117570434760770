export const AidesOptions = [
    { ph: 'RSA' },
    { ph: 'Prime d’activité' },
    { ph: 'AAH' },
    { ph: 'MVA' },
    { ph: 'ASPA' },
    { ph: 'CSS' },
    { ph: 'ASI' },
    { ph: 'Allocation étudiant en difficulté' },
    { ph: 'ASF' },
    { ph: 'Complément familial' },
    { ph: 'PAJE' },
    { ph: 'APL' },
    { ph: 'Chèque énergie' },
    { ph: 'Allocations familiales' },
    { ph: 'APA' },
    { ph: 'Garantie Jeunes' },
    { ph: 'CPF ' },
    { ph: 'MOBILI-JEUNE' },
    { ph: 'LOCA-PASS' },
    { ph: 'Garantie VISALE' },
]

export const PhasesOptions = [
    { ph: 'Récupération PJ' },
    { ph: 'Mandat prélèvement à signer' },
    { ph: 'Demande à faire' },
    { ph: 'Demande effectuée' },
    { ph: 'Récupération nouvelle PJ' },
    { ph: 'Nouvelle PJ envoyées à CAF' },
    { ph: 'Récupération num alloc poste' },
    { ph: 'Récupération mdp poste' },
    { ph: 'Demande acceptée' },
    { ph: 'Demande refusée' },
    { ph: 'Abandon du client' },
]

export const gradeOptions = [
    { label: 'Pour la gestion des clients', value: 'Agent' },
    { label: 'Pour la gestion du blog', value: 'Blogger' },
    { label: 'Pour la gestion des simulations', value: 'Commercial' },
]