import FormikFieldController from "../uikits/form"
import { confirmUpdateWithPasswordsFields, createClientFields, allFields } from "../utils/form/fields"


function getClientField(notPassword) {
    let fields = createClientFields;
    if (!notPassword) {
        fields = [...fields, ...confirmUpdateWithPasswordsFields]
    }
    return fields;
    // confirmUpdateWithPasswordsFields
}
export const UserFormFields = ({ grade, allAgents, notPassword }) => {
    let formFields = getClientField(notPassword);
    return <>
        {
            formFields.map((item, i) => <FormikFieldController
                {...item}
                key={'client field nb' + i}
            // autoComplete="new-password"
            />)
        }
        {
            grade === 'Admin' &&
            <div>
                <p>Les agents :</p>
                <ul>
                    {
                        allAgents.map((aag, k) => <li key={"Agent info nb" + k}>
                            {"Identifiant de l'agent " + aag.nom + " " + aag.prenom + ": " + aag._id}
                        </li>)
                    }
                </ul>
                <FormikFieldController
                    {...allFields.agentId}
                />
            </div>
        }
    </>
}
