import ClientInfosTable from "../table.js";

export const ClientDocuments = ({ documents }) => {
    return <ClientInfosTable props={{
        infoType: "document", infos: documents,
        firstColumnTitle: "Titre", firstColumnKey: "title",
        actionsBtns: "up,delete"
    }} />

}






