import { useState, useEffect } from 'react'
import axios from 'axios';
import useBlogCategory from '../../../Hooks/blog/useBlogCategory';
import { editorBtnsList } from '../../../Rawdata/editor';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { FormikProvider, useFormik, Form } from 'formik';
import { newBlogArticleFields } from '../../../utils/form/fields';
import FormikFieldController from '../../../uikits/form';
import { FormButton } from '../../../uikits/button';

function UpdateBlogArticle({ actionId }) {
    const [article, setArticle] = useState('');
    const { blogCategorys, bcLoading } = useBlogCategory();
    const [editorState, setES] = useState('');
    const [operationLoad, setOL] = useState(false);

    const formik = useFormik({
        initialValues: { title: '', banner: '', description: '', categoryId: '' },
        onSubmit: handleSubmit,
        validateOnMount: true
    });
    const { isValid, setFieldValue, isSubmitting } = formik;

    useEffect(() => {
        axios.get('/admin/blog/article/' + actionId)
            .then(res => {
                setArticle(res.data);
                setFieldValue('title', res.data.title);
                setFieldValue('description', res.data.description);
                setFieldValue('categoryId', res.data.categoryId._id);
                setES(res.data.content)
            }).catch(err => {
                alert('not exist')
            })
    }, []);


    function handleSubmit(formValues) {
        setOL(true)
        const formdata = new FormData();
        if (formValues.banner != '') {
            formdata.append('banner', formValues.banner);
        }
        formdata.append('title', formValues.title);
        formdata.append('description', formValues.description);
        formdata.append('categoryId', formValues.categoryId);
        formdata.append('content', '<div  class="editorContentBox" >' + editorState + '</div>');

        axios.put('/admin/blog/article/update/' + article._id, formdata)
            .then(res => {
                if (res.data._id) {
                    window.location = '/Blog/article/view/' + res.data._id
                }
            }).catch(err => {
                alert('Une erreur est survenue');
            }).finally(() => setOL(false))
    }

    if (bcLoading)
        return ''
    return (
        <div>
            <h1>Modifiez votre article</h1>
            <FormikProvider value={formik}>
                <Form>
                    {
                        newBlogArticleFields(blogCategorys).map((item, i) => <FormikFieldController
                            key={'article up field nb' + i}
                            {...item}
                        />)
                    }
                    <SunEditor placeholder="Votre article ici..."
                        setContents={editorState}
                        onChange={setES}
                        // defaultValue={editorState}
                        setOptions={{
                            buttonList: editorBtnsList,
                            minHeight: '50vh'
                        }}
                    />
                    <FormButton text={isSubmitting ? "" : "Enregistrer"}
                        isValid={isSubmitting ? false : isValid}
                    />
                </Form>
            </FormikProvider>


        </div>
    )
}

export default UpdateBlogArticle