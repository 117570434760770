import { useEffect, useState } from "react"
import axios from "axios";;


const useEmail = (clientId, initialEmail = []) => {
    const [emails, setEmails] = useState(initialEmail);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const allEmails = await axios.get('/admin/documents/' + clientId)
            setEmails(allEmails.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchEmails = () => {
        setRefecth(true)
    };

    return { emails, refetchEmails };
}

export default useEmail;