import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PageTitle } from '../GlobalComponents/other'
import { withAuth } from '../hocs/withAuth';
function Avis() {
    const [avis, setAvis] = useState(0);
    const [message, setMessage] = useState(null)
    useEffect(() => {
        axios.get("/avis/get")
            .then(res => {
                setAvis(res.data)
            })
    }, [])

    function handleSub() {
        axios.post("/avis/update", { avisCount: avis })
            .then(res => setMessage("Le nombre d'avis a bien été mis a jour"))
    }
    return (
        <div className='avis'>
            {
                <>
                    <PageTitle title={'Avis Trustpilot'} />
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        handleSub()
                    }} >
                        <div className='formSegment'>
                            <label>Nombre d'avis actuel a modifier</label>
                            <input type="number" value={avis}

                                onChange={(event) => setAvis(event.target.value)} />
                        </div>
                        <div className="formBtn">
                            <button>Enregistrer</button>
                        </div>
                        {
                            message
                        }
                    </form>
                </>
            }
        </div>
    )
}

export default withAuth(Avis)
