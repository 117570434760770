import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { setCurrentClientToStore } from "../Store/actions/clientActions";
import { PageTitle } from "../GlobalComponents/other";
import useNotifications from "../Hooks/useNotifications";
import { withAuth } from "../hocs/withAuth";
import { PageNavigator, SectionLoader } from "../uikits/others";


export const Notification = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const { notifications, nLoading, refetchNotifications } = useNotifications(currentPage);
    const [allUsers, setAllUsers] = useState([])
    const [loading, setL] = useState(true);


    function getAgentName(agentId) {
        const filter = allUsers.filter(us => us.grade == "Agent" && us._id === agentId)
        return filter[0].nom + ' ' + filter[0].prenom
    }

    function getSuscriberName(suscriberId) {
        const filter = allUsers.filter(us => us.grade == "Client" && us._id === suscriberId)
        if (filter.length > 0) {
            return filter[0].nom + ' ' + filter[0].prenom
        } else {
            return false
        }
    }

    useEffect(() => {
        axios.get('/admin/all')
            .then(res => setAllUsers(res.data.reverse()))
            .catch(err => console.log(err))
            .finally(() => setL(false))

    }, []);
    useEffect(() => {
        if (currentPage != 1) {
            refetchNotifications()
        }
    }, [currentPage])

    if (loading || nLoading)
        return <SectionLoader />
    return <div className="notification">
        {
            allUsers.length > 0 ?
                <>
                    <PageTitle title={'Notifications dernier(e)s inscrit(e)s '} />
                    <section className="nts-list">
                        {
                            notifications.notifications.map((sn, index) => <div key={"subscriber nt nb" + index}
                                className="ns-notif">
                                {
                                    !getSuscriberName(sn.clientId) ? <p>
                                        Client supprimé(Id du client: {sn.clientId} )
                                    </p>
                                        : <>
                                            <section>
                                                <p>
                                                    Nouvelle inscription de <b>
                                                        {allUsers.length ? getSuscriberName(sn.clientId) : '...'}
                                                    </b>
                                                </p>
                                                <p>
                                                    <span>Agent attribué</span> <b>
                                                        {allUsers.length ? getAgentName(sn.userId) : '...'}
                                                    </b>
                                                </p>
                                            </section>
                                            <button>
                                                <Link to={"/Client/" + sn.clientId + "/ressources"}
                                                    onClick={() => dispatch(setCurrentClientToStore(allUsers.filter(item => item._id === sn.clientId)[0]))}
                                                >Voir infos</Link>
                                            </button>
                                        </>
                                }
                            </div>)
                        }
                        <PageNavigator currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            datas={notifications} />
                    </section>
                </>
                : 'Pas de notification'
        }

    </div>
}
export default withAuth(Notification);