import { useEffect, useState } from "react"
import axios from "axios";

const useRessource = (clientId, initialRessource = []) => {
    const [ressources, setRessources] = useState(initialRessource);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const allRessources = await axios.get('/admin/ressources/' + clientId)
            setRessources(allRessources.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchRessources = () => {
        setRefecth(true)
    };

    return { ressources, refetchRessources };
}

export default useRessource;