import { useEffect, useState } from "react"
import axios from "axios";

const useAppEmails = () => {
    const [emailsCount, setEmailsCount] = useState(0);
    const [emails, setEmails] = useState([]);
    const [emailsFilenames, setEmailsFilenames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refetch, setRefecth] = useState(false);

    useEffect(async () => {
        const fetchData = async () => {
            try {
                const result = await axios.get('/admin/emails-edit/emails-count');
                setEmailsCount(result.data.jsonFilesCount);
                setEmails(result.data.jsonFilesData);
                setEmailsFilenames(result.data.jsonFilesNames)
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false)
            }
        };
        fetchData();
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return {
        emailsCount, emails, emailsFilenames,
        appEmailsLoading: loading,
        refetchAppEmails: refetchDatas,
        appEmailError: error
    };
}

export default useAppEmails;