import { useEffect, useState } from "react"
import axios from "axios";

const useSimulation = (clientEmail, clientTelephone, initialSimulation = []) => {
    const [simulations, setSimulations] = useState(initialSimulation);
    const [refetch, setRefecth] = useState(false)
    useEffect(async () => {
        try {
            const allSimulations = await axios.get('/admin/simulations/' + clientEmail + '/' + clientTelephone)
            setSimulations(allSimulations.data)
        } catch (error) {

        }
        return setRefecth(false)
    }, [refetch])


    const refetchSimulations = () => {
        setRefecth(true)
    };

    return { simulations, refetchSimulations };
}

export default useSimulation;