import { useEffect, useState } from "react"
import axios from "axios";
const useClientsContacts = () => {
    const [datas, setDatas] = useState([]);
    const [loading, setL] = useState(true);
    const [refetch, setRefecth] = useState(false);

    useEffect(async () => {
        try {
            const allDatas = await axios.get('/form/contact/list')
            setDatas(allDatas.data)
        } catch (error) {

        } finally {
            setL(false)
        }
        return setRefecth(false)

    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return {
        clientContacts: datas,
        ccLoading: loading, refetchDatas
    };
}

export default useClientsContacts;